import { render, staticRenderFns } from "./DashboardCreate.vue?vue&type=template&id=1650dbde&scoped=true&"
import script from "./DashboardCreate.vue?vue&type=script&lang=js&"
export * from "./DashboardCreate.vue?vue&type=script&lang=js&"
import style0 from "./DashboardCreate.vue?vue&type=style&index=0&id=1650dbde&prod&lang=scss&"
import style1 from "./DashboardCreate.vue?vue&type=style&index=1&id=1650dbde&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1650dbde",
  null
  
)

export default component.exports