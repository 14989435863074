<template>
  <div>
    <b-row class="content-header">
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="md-12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="md-12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              {{ this.$route.query.from ? 'Duplicate Dashboard View' : 'Create New Dashboard View' }}
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="dashboardViewCreateForm"
      #default="{ invalid }"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Dashboard View*"
                label-for="dashboard-view"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dashboard View"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="dashboard-view"
                    v-model="viewName"
                    placeholder="Enter a name for the dashboard view"
                    :state="errors.length > 0 || nameValidation ? false : null"
                    name="name"
                    @input="nameValidation == true ? (nameValidation = false) : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-9">
              <validation-provider
                #default="{ errors }"
                name="Department"
                vid="department"
                rules="required"
              >
                <b-form-group
                  label="Department*"
                  label-for="department"
                  label-cols-md="2"
                  :state="errors.length > 0 || departmentValidation ? false : null"
                >
                  <v-select
                    id="department"
                    v-model="departments"
                    label="name"
                    placeholder="Select department(s)"
                    multiple
                    :options="departmentOptions"
                    :reduce="(name) => name._id"
                    :clearable="true"
                    @input="makeUserOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="departmentValidation"
                    class="text-danger"
                  >
                    {{ departmentError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="departments.length"
              cols="md-9"
            >
              <validation-provider
                #default="{ errors }"
                name="Users"
                vid="users"
                rules="required"
              >
                <b-form-group
                  label="Users*"
                  label-for="users"
                  label-cols-md="2"
                  :state="errors.length > 0 || usersValidation ? false : null"
                >
                  <v-select
                    id="users"
                    v-model="users"
                    label="name"
                    placeholder="Select department user(s)"
                    multiple
                    :options="computedUserOption"
                    :reduce="o => o._id"
                    :clearable="true"
                    :selectable="option => !option.hasOwnProperty('category')"
                    @input="usersValidation == true ? (usersValidation = false) : null"
                  >
                    <template #option="{ category, name }">
                      <div
                        v-if="category"
                        class="group"
                      >
                        {{ category }}
                      </div>
                      {{ name }}
                    </template>
                  </v-select>

                  <small class="text-muted">Please note that the dashboard data visibility is subject to user’s role permission.</small>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="usersValidation"
                    class="text-danger"
                  >
                    {{ usersError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-work-flow-status"
                  label-cols-md="3"
                  :state="errors.length > 0 || statusValidation ? false : null"
                >
                  <v-select
                    id="h-work-flow-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="(title) => title.code"
                    :clearable="false"
                    @input="
                      statusValidation == true
                        ? (statusValidation = false)
                        : null
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <b-tabs
          pills
          class="dashboard__tabs"
        >
          <b-tab active>
            <template #title>
              <b-card
                no-body
                class="dashboard__tab-card"
              >
                <b-card-body
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    variant="light-primary"
                    size="40"
                    rounded
                  >
                    <feather-icon
                      size="20"
                      icon="PieChartIcon"
                      class="mr-0"
                    />
                  </b-avatar>
                  <div class="truncate ml-2">
                    <h2>Overview</h2>
                  </div>
                </b-card-body>
              </b-card>
            </template>

            <b-card class="dashboard__tab-content-card">
              <div class="d-flex justify-content-between">
                <p class="tab-sub-title">
                  An overview of statistical data from your assigned modules.
                </p>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="openOverviewWidgetModal"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  Add a new widget
                </b-button>
              </div>
              <hr>
              <div
                v-if="!overviewWidgets.length"
                class="dashboard_empty_block-wrapper"
              >
                <div class="dashboard_empty_block">
                  <h4>There’s nothing here yet</h4>
                  <b-button
                    type="button"
                    variant="flat-primary"
                    @click="openOverviewWidgetModal"
                  >
                    <span class="align-middle">Add a new widget</span>
                  </b-button>
                </div>
              </div>
              <section v-else>
                <!-- <b-row class="match-height">
                  <div
                    v-for="(widget, key) in overviewWidgets"
                    :key="key"
                  >
                    <MonthlyBookingCounts
                      v-if="widget.subModule == 'Current month: Booking Activity'"
                    />
                  </div>
                </b-row> -->
                <draggable
                  v-model="overviewWidgets"
                  handle=".draggable-task-handle"
                  class="todo-task-list media-list row"
                >
                  <b-col
                    v-for="(widget, key) in overviewWidgets"
                    :key="key"
                    :lg="widget.module == 'budget overview' ? 12 : 6"
                    class="todo-item mb-1"
                  >
                    <div
                      class="drag-element overview-widget-preview"
                    >
                      <div style="width: 20px;">
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="draggable-task-handle d-inline"
                        />
                      </div>

                      <div
                        v-if="widget.subModule == 'Current month: Booking Activity'"
                        class="overview-widget__single-block"
                      >
                        <MonthlyBookingCounts />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Today only: Booking Status'"
                        class="overview-widget__single-block"
                      >
                        <DailyBookingCounts />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Digital Service Form status'"
                        class="overview-widget__single-block"
                      >
                        <ServiceFormStatusCount />
                      </div>

                      <div
                        v-else-if="widget.module == 'service forms' && widget.subModule == 'Pending Status Aging Days'"
                        class="overview-widget__single-block"
                      >
                        <ServiceFormAgeCount />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Last 30D: Pending status'"
                        class="overview-widget__single-block"
                      >
                        <ServiceRequestThirtyDays />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Today only: Pending status'"
                        class="overview-widget__single-block"
                      >
                        <ServiceRequestToday />
                      </div>

                      <div
                        v-else-if="widget.module == 'service requests' && widget.subModule == 'Pending Status Aging Days'"
                        class="overview-widget__single-block"
                      >
                        <ServiceRequestAgeCount />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Quarterly Payment Status'"
                        class="overview-widget__single-block"
                      >
                        <ServiceRequestQuarterlyPayment />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Awaiting Approval Status'"
                        class="overview-widget__single-block"
                      >
                        <ServiceRequestAwaitingApproval />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Stores'"
                        class="overview-widget__single-block"
                      >
                        <InventoryRequestsByStore
                          :year-option="Number(widget.year)"
                          :store-options="widget.stores"
                        />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'Status'"
                        class="overview-widget__single-block"
                      >
                        <InventoryRequestsByStatus
                          :year-option="Number(widget.year)"
                          :store-options="widget.stores"
                        />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'PRF'"
                        class="overview-widget__single-block"
                      >
                        <PurchaseRequestCount />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'QCF'"
                        class="overview-widget__single-block"
                      >
                        <QuotationCount />
                      </div>

                      <div
                        v-else-if="widget.subModule == 'PO'"
                        class="overview-widget__single-block"
                      >
                        <PurchaseOrderCount />
                      </div>

                      <div
                        v-else-if="widget.module == 'budget overview'"
                        class="overview-widget__single-block m-0"
                      >
                        <BudgetOverview
                          :cost-center="widget.costCenter"
                          :budgetary-period="widget.budgetaryPeriod"
                          :expense-category-type="widget.expenseCategoryType"
                          :expense-categories="widget.expenseCategories"
                        />
                      </div>

                      <div class="dropdown">
                        <b-dropdown
                          variant="link"
                          no-caret
                          right
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="editOverviewWidget(key)">
                            Edit
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteOverviewWidget(key)">
                            Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </b-col>
                </draggable>
              </section>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-card
                no-body
                class="dashboard__tab-card"
              >
                <b-card-body
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    variant="light-primary"
                    size="40"
                    rounded
                  >
                    <feather-icon
                      size="20"
                      icon="GridIcon"
                      class="mr-0"
                    />
                  </b-avatar>
                  <div class="truncate ml-2">
                    <h2>My Dashboard</h2>
                  </div>
                </b-card-body>
              </b-card>
            </template>
            <b-card class="dashboard__tab-content-card">
              <div class="d-flex justify-content-between">
                <p class="tab-sub-title">
                  The following items require your action or follow-up.
                </p>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="openMyDashboardWidgetModal"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  Add a new widget
                </b-button>
              </div>
              <hr>
              <div
                v-if="!myDashboardWidgets.length"
                class="dashboard_empty_block-wrapper"
              >
                <div class="dashboard_empty_block">
                  <h4>There’s nothing here yet</h4>
                  <b-button
                    type="button"
                    variant="flat-primary"
                    @click="openMyDashboardWidgetModal"
                  >
                    <span class="align-middle">Add a new widget</span>
                  </b-button>
                </div>
              </div>
              <section v-else>
                <draggable
                  v-model="myDashboardWidgets"
                  handle=".draggable-task-handle"
                  class="todo-task-list media-list row"
                >
                  <b-col
                    v-for="(widget, key) in myDashboardWidgets"
                    :key="key"
                    lg="12"
                    class="todo-item p-0"
                  >
                    <div
                      class="drag-element overview-widget-preview transparent-bg p-0"
                    >
                      <div style="width: 5px;">
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="draggable-task-handle no-left d-inline"
                          style="margin-top: -20px"
                        />
                      </div>

                      <div
                        v-if="widget.module == 'Bookings'"
                        class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                      >
                        <BookingsList
                          :widget-name="widget.widgetName"
                          :status-options="widget.statusOptions"
                          :filter-option="widget.filterBy"
                          :date-option="widget.dataFreshness"
                          :date-range-option="widget.dateRangeOption"
                          :table-columns="widget.tableColumns"
                        />
                        <hr
                          :class="{'d-none' : key == myDashboardWidgets.length - 1}"
                          class="mx-1"
                        >
                      </div>

                      <div
                        v-else-if="widget.module == 'Service Forms'"
                        class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                      >
                        <ServiceFormList
                          :widget-name="widget.widgetName"
                          :status-options="widget.statusOptions"
                          :form-status-options="widget.extraOptions"
                          :filter-option="widget.filterBy"
                          :date-option="widget.dataFreshness"
                          :date-range-option="widget.dateRangeOption"
                          :table-columns="widget.tableColumns"
                        />
                        <hr
                          :class="{'d-none' : key == myDashboardWidgets.length - 1}"
                          class="mx-1"
                        >
                      </div>

                      <div
                        v-else-if="widget.module == 'Service Requests'"
                        class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                      >
                        <ServiceRequestList
                          :widget-name="widget.widgetName"
                          :status-options="widget.statusOptions"
                          :payment-status-options="widget.extraOptions"
                          :filter-option="widget.filterBy"
                          :date-option="widget.dataFreshness"
                          :date-range-option="widget.dateRangeOption"
                          :table-columns="widget.tableColumns"
                        />
                        <hr
                          :class="{'d-none' : key == myDashboardWidgets.length - 1}"
                          class="mx-1"
                        >
                      </div>

                      <div
                        v-else-if="widget.module == 'Inventory Requests'"
                        class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                      >
                        <InventoryRequestList
                          :widget-name="widget.widgetName"
                          :status-options="widget.statusOptions"
                          :store-options="widget.extraOptions"
                          :filter-option="widget.filterBy"
                          :date-option="widget.dataFreshness"
                          :date-range-option="widget.dateRangeOption"
                          :table-columns="widget.tableColumns"
                        />
                        <hr
                          :class="{'d-none' : key == myDashboardWidgets.length - 1}"
                          class="mx-1"
                        >
                      </div>

                      <div
                        v-else-if="widget.module == 'Purchase Requests'"
                        class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                      >
                        <PurchaseRequestList
                          :widget-name="widget.widgetName"
                          :budget-status-options="widget.statusOptions"
                          :request-type="widget.extraOptions"
                          :filter-option="widget.filterBy"
                          :date-option="widget.dataFreshness"
                          :date-range-option="widget.dateRangeOption"
                          :table-columns="widget.tableColumns"
                        />
                        <hr
                          :class="{'d-none' : key == myDashboardWidgets.length - 1}"
                          class="mx-1"
                        >
                      </div>

                      <div
                        v-else-if="widget.module == 'Vendor Payments'"
                        class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                      >
                        <VendorPaymentList
                          :widget-name="widget.widgetName"
                          :request-type="widget.statusOptions"
                          :filter-option="widget.filterBy"
                          :date-option="widget.dataFreshness"
                          :date-range-option="widget.dateRangeOption"
                          :table-columns="widget.tableColumns"
                        />
                        <hr
                          :class="{'d-none' : key == myDashboardWidgets.length - 1}"
                          class="mx-1"
                        >
                      </div>

                      <div
                        v-else-if="widget.module == 'Vendors List'"
                        class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                      >
                        <VendorList
                          :widget-name="widget.widgetName"
                          :status-options="widget.statusOptions"
                          :filter-option="widget.filterBy"
                          :date-option="widget.dataFreshness"
                          :date-range-option="widget.dateRangeOption"
                          :table-columns="widget.tableColumns"
                        />
                        <hr
                          :class="{'d-none' : key == myDashboardWidgets.length - 1}"
                          class="mx-1"
                        >
                      </div>

                      <div
                        class="d-flex"
                        style="position: absolute; right: 0.5%;"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="flat-primary"
                          style="padding: 0.5rem 0.8rem;"
                          @click="deleteMyDashboardWidget(key)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-50"
                          />
                          <span class="align-middle">Delete</span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="flat-primary"
                          style="padding: 0.5rem 0.8rem;"
                          @click="editMyDashboardWidget(key)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Edit</span>
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </draggable>
              </section>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-card
                no-body
                class="dashboard__tab-card"
              >
                <b-card-body
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    variant="light-primary"
                    size="40"
                    rounded
                  >
                    <feather-icon
                      size="20"
                      icon="UsersIcon"
                      class="mr-0"
                    />
                  </b-avatar>
                  <div class="truncate ml-2">
                    <h2>Department Dashboard</h2>
                  </div>
                </b-card-body>
              </b-card>
            </template>
            <b-card class="dashboard__tab-content-card">
              <div class="d-flex justify-content-between">
                <p class="tab-sub-title">
                  These are outstanding items for your department(s).
                </p>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="openDepartmentDashboardWidgetModal"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  Add a new tab
                </b-button>
              </div>
              <hr>
              <div
                v-if="!departmentWidgets.length"
                class="dashboard_empty_block-wrapper"
              >
                <div
                  class="dashboard_empty_block"
                >
                  <h4>There’s nothing here yet</h4>
                  <b-button
                    type="button"
                    variant="flat-primary"
                    @click="openDepartmentDashboardWidgetModal"
                  >
                    <span class="align-middle">Add a new widget</span>
                  </b-button>
                </div>
              </div>

              <b-tabs
                v-else
                v-model="departmentSubTab"
                pills
                class="department__tabs"
              >
                <template v-for="(widget, key) in departmentWidgets">
                  <b-tab
                    v-if="widget.module == 'Bookings'"
                    :key="key"
                  >
                    <template #title>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        split
                        right
                        split-variant="outline-primary"
                        variant="outline-primary"
                        class="mt-2 department__tab-dropdown"
                      >
                        <template #button-content>
                          <span>Bookings <span class="rounded-number">{{ bookingsCount }}</span></span>
                        </template>
                        <b-dropdown-item @click="editDepartmentDashboardWidget(key)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteDepartmentDashboardWidget(key)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <DepartmentBookingsList
                      :status-options="widget.statusOptions"
                      :date-option="widget.dataFreshness"
                      :date-range-option="widget.dateRangeOption"
                      :table-columns="widget.tableColumns"
                      :bookings-count.sync="bookingsCount"
                    />
                  </b-tab>
                  <b-tab
                    v-else-if="widget.module == 'Service Forms'"
                    :key="key"
                  >
                    <template #title>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        split
                        right
                        split-variant="outline-primary"
                        variant="outline-primary"
                        class="mt-2 department__tab-dropdown"
                      >
                        <template #button-content>
                          <span>Service Forms <span class="rounded-number">{{ serviceFormsCount }}</span></span>
                        </template>
                        <b-dropdown-item @click="editDepartmentDashboardWidget(key)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteDepartmentDashboardWidget(key)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <DepartmentServiceFormList
                      :status-options="widget.statusOptions"
                      :payment-status-options="widget.extraOptions"
                      :date-option="widget.dataFreshness"
                      :date-range-option="widget.dateRangeOption"
                      :table-columns="widget.tableColumns"
                      :service-forms-count.sync="serviceFormsCount"
                    />
                  </b-tab>
                  <b-tab
                    v-else-if="widget.module == 'Service Requests'"
                    :key="key"
                  >
                    <template #title>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        split
                        right
                        split-variant="outline-primary"
                        variant="outline-primary"
                        class="mt-2 department__tab-dropdown"
                      >
                        <template #button-content>
                          <span>Service Requests <span class="rounded-number">{{ serviceRequestsCount }}</span></span>
                        </template>
                        <b-dropdown-item @click="editDepartmentDashboardWidget(key)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteDepartmentDashboardWidget(key)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <DepartmentServiceRequestList
                      :status-options="widget.statusOptions"
                      :payment-status-options="widget.extraOptions"
                      :date-option="widget.dataFreshness"
                      :date-range-option="widget.dateRangeOption"
                      :table-columns="widget.tableColumns"
                      :service-requests-count.sync="serviceRequestsCount"
                    />
                  </b-tab>
                  <b-tab
                    v-else-if="widget.module == 'Inventory Requests'"
                    :key="key"
                  >
                    <template #title>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        split
                        right
                        split-variant="outline-primary"
                        variant="outline-primary"
                        class="mt-2 department__tab-dropdown"
                      >
                        <template #button-content>
                          <span>Inventory Requests <span class="rounded-number">{{ inventoryRequestsCount }}</span></span>
                        </template>
                        <b-dropdown-item @click="editDepartmentDashboardWidget(key)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteDepartmentDashboardWidget(key)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <DepartmentInventoryRequestList
                      :status-options="widget.statusOptions"
                      :store-options="widget.extraOptions"
                      :date-option="widget.dataFreshness"
                      :date-range-option="widget.dateRangeOption"
                      :table-columns="widget.tableColumns"
                      :inventory-requests-count.sync="inventoryRequestsCount"
                    />
                  </b-tab>
                  <b-tab
                    v-else-if="widget.module == 'Purchase Requests'"
                    :key="key"
                  >
                    <template #title>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        split
                        right
                        split-variant="outline-primary"
                        variant="outline-primary"
                        class="mt-2 department__tab-dropdown"
                      >
                        <template #button-content>
                          <span>Purchase Requests <span class="rounded-number">{{ purchaseRequestsCount }}</span></span>
                        </template>
                        <b-dropdown-item @click="editDepartmentDashboardWidget(key)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteDepartmentDashboardWidget(key)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <DepartmentPurchaseRequestList
                      :budget-status-options="widget.statusOptions"
                      :request-type="widget.extraOptions"
                      :date-option="widget.dataFreshness"
                      :date-range-option="widget.dateRangeOption"
                      :table-columns="widget.tableColumns"
                      :purchase-requests-count.sync="purchaseRequestsCount"
                    />
                  </b-tab>
                  <b-tab
                    v-else-if="widget.module == 'Vendor Payments'"
                    :key="key"
                  >
                    <template #title>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        split
                        right
                        split-variant="outline-primary"
                        variant="outline-primary"
                        class="mt-2 department__tab-dropdown"
                      >
                        <template #button-content>
                          <span>Vendor Payments <span class="rounded-number">{{ vendorPaymentsCount }}</span></span>
                        </template>
                        <b-dropdown-item @click="editDepartmentDashboardWidget(key)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteDepartmentDashboardWidget(key)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <DepartmentVendorPaymentList
                      :request-type="widget.statusOptions"
                      :date-option="widget.dataFreshness"
                      :date-range-option="widget.dateRangeOption"
                      :table-columns="widget.tableColumns"
                      :vendor-payments-count.sync="vendorPaymentsCount"
                    />
                  </b-tab>
                  <b-tab
                    v-else-if="widget.module == 'Vendors List'"
                    :key="key"
                  >
                    <template #title>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        split
                        right
                        split-variant="outline-primary"
                        variant="outline-primary"
                        class="mt-2 department__tab-dropdown"
                      >
                        <template #button-content>
                          <span>Vendors List <span class="rounded-number">{{ vendorsCount }}</span></span>
                        </template>
                        <b-dropdown-item @click="editDepartmentDashboardWidget(key)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteDepartmentDashboardWidget(key)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <DepartmentVendorList
                      :status-options="widget.statusOptions"
                      :date-option="widget.dataFreshness"
                      :date-range-option="widget.dateRangeOption"
                      :table-columns="widget.tableColumns"
                      :vendors-count.sync="vendorsCount"
                    />
                  </b-tab>
                </template>
              </b-tabs>
            </b-card>
          </b-tab>
        </b-tabs>
        <b-nav class="wrap-border save-nav">
          <li class="nav-item ml-auto">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{
                name: 'dashboard-views-index'
              }"
            >
              Cancel
            </b-button>
          </li>
          <li class="nav-item mr-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Dashboard</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>

    <!-- create overview widget modal -->
    <b-modal
      id="create-overview-widget-modal"
      ref="create-overview-widget-modal"
      modal-class="dashboard__create-widget-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Add a new Widget"
      size="lg"
      @cancel="closeCreateWidgetModal"
      @ok="submitCreateWidgetData"
    >
      <b-form @submit.prevent="submitCreateWidgetData">
        <validation-observer
          ref="overviewWidgetCreateForm"
        >
          <b-row class="border rounded">
            <b-col
              md="5"
              class="border-right"
            >
              <div class="widget__step-header">
                <h3>Step 1</h3>
                <p>Please select a sub-module</p>
              </div>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sub Module"
                  vid="overviewSubmodule == ''"
                  rules="required"
                >
                  <b-form-radio-group
                    id="overviewSubmodule"
                    v-model="overviewWidgetObject.module"
                    class="form-custom-input-checkbox regular ml-50"
                    @change="makeOverviewSecondStep"
                  >
                    <app-collapse
                      accordion
                      class="widget__app-collapse"
                    >
                      <app-collapse-item
                        title="Appointment"
                        :is-visible="overviewWidgetObject.module == 'bookings'"
                      >
                        <b-form-radio
                          v-for="appointmentOption in appointmentLeftOptions"
                          :key="appointmentOption.value"
                          :value="appointmentOption.value"
                        >
                          {{ appointmentOption.label }}
                        </b-form-radio>
                      </app-collapse-item>

                      <app-collapse-item
                        title="Operations"
                        :is-visible="['service forms', 'service requests'].includes(overviewWidgetObject.module)"
                      >
                        <b-form-radio
                          v-for="operationOption in operationsLeftOptions"
                          :key="operationOption.value"
                          :value="operationOption.value"
                        >
                          {{ operationOption.label }}
                        </b-form-radio>
                      </app-collapse-item>

                      <app-collapse-item
                        title="Inventory"
                        :is-visible="overviewWidgetObject.module == 'inventory requests'"
                      >
                        <b-form-radio
                          v-for="operationOption in inventoryLeftOptions"
                          :key="operationOption.value"
                          :value="operationOption.value"
                        >
                          {{ operationOption.label }}
                        </b-form-radio>
                      </app-collapse-item>

                      <app-collapse-item
                        title="Purchasing"
                        :is-visible="overviewWidgetObject.module == 'purchase requests'"
                      >
                        <b-form-radio
                          v-for="operationOption in purchasingLeftOptions"
                          :key="operationOption.value"
                          :value="operationOption.value"
                        >
                          {{ operationOption.label }}
                        </b-form-radio>
                      </app-collapse-item>

                      <app-collapse-item
                        title="Budgeting"
                        :is-visible="overviewWidgetObject.module == 'budget overview'"
                      >
                        <b-form-radio
                          v-for="operationOption in budgetingLeftOptions"
                          :key="operationOption.value"
                          :value="operationOption.value"
                        >
                          {{ operationOption.label }}
                        </b-form-radio>
                      </app-collapse-item>
                    </app-collapse>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!overviewWidgetObject.module"
              md="7"
              class="widget__second-step"
            >
              <div class="widget__step-header-empty">
                <h3>Step 2</h3>
                <p>Configure the selected sub-module</p>
              </div>
              <div class="widget__second-step-blank-text">
                <p>Please select a module data in step 1 to do configuration</p>
              </div>
            </b-col>
            <b-col
              v-else
              md="7"
            >
              <div class="widget__step-header">
                <h3>Step 2</h3>
                <p>Configure the selected sub-module</p>
              </div>
              <validation-provider
                v-if="overviewSecondStepType === 'radio'"
                #default="{ errors }"
                name="Step 2"
                vid="overviewOptionValue"
                rules="required"
              >
                <b-form-radio-group
                  id="h-activity-type-overviewOptionValue"
                  v-model="overviewWidgetObject.subModule"
                  button-variant="outline-primary"
                  buttons
                  class="widget__step-right-option"
                >
                  <b-form-radio
                    v-for="(selection, sIndex) in overviewSecondStepOptions"
                    :key="sIndex"
                    :value="selection.value"
                    class="mb-1"
                  >
                    <div class="widget__right-selection-top">
                      <feather-icon
                        v-if="overviewWidgetObject.subModule === selection.value"
                        icon="CheckIcon"
                        class="widget__right-top-icon"
                        size="18"
                      />
                      <div class="right__widget-text">
                        <h4 class="text-left text-primary">
                          {{ selection.text }}
                        </h4>
                        <p>{{ selection.description }}</p>
                      </div>
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="overviewOptionValueValidation"
                  class="text-danger"
                >
                  {{ overviewOptionValueError }}
                </small>
              </validation-provider>

              <validation-provider
                v-else-if="overviewSecondStepType === 'inventory'"
                #default="{ errors }"
                name="Step 2"
                vid="overviewOptionValue"
                rules="required"
              >
                <b-form-radio-group
                  id="h-activity-type-overviewOptionValue"
                  v-model="overviewWidgetObject.subModule"
                  button-variant="outline-primary"
                  buttons
                  class="widget__step-right-option"
                >
                  <b-form-radio
                    v-for="(selection, sIndex) in overviewInventoryRequestOptions"
                    :key="sIndex"
                    :value="selection.value"
                    class="mb-1"
                  >
                    <div class="widget__right-selection-top">
                      <feather-icon
                        v-if="overviewWidgetObject.subModule === selection.value"
                        icon="CheckIcon"
                        class="widget__right-top-icon"
                        size="18"
                      />
                      <div class="right__widget-text">
                        <h4 class="text-left text-primary">
                          {{ selection.text }}
                        </h4>
                        <p>{{ selection.description }}</p>
                      </div>
                    </div>
                    <div
                      v-if="overviewWidgetObject.subModule === selection.value"
                      class="widget__sub-content"
                    >
                      <validation-provider
                        #default="{ errors: err }"
                        name="Year"
                        vid="inventoryYear"
                        rules="required"
                      >
                        <b-form-group
                          label="Select a year"
                          label-for="h-work-flow-status"
                          class="custom__input"
                        >
                          <v-select
                            id="h-work-flow-status"
                            v-model="overviewWidgetObject.year"
                            :options="yearOptions"
                            :clearable="false"
                          />
                          <small class="text-danger">{{ err[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                      <div class="budgeting__store-location">
                        <h4>Select store location</h4>
                        <p>It shows the total count of Inventory Request Open status for the store. </p>
                        <validation-provider
                          #default="{ errors: err }"
                          name="Store"
                          vid="inventoryStore"
                          rules="required"
                        >
                          <b-form-group>
                            <b-form-checkbox
                              v-for="store in inventoryStoreOptions"
                              :key="store._id"
                              v-model="overviewWidgetObject.stores"
                              :value="store._id"
                              name="flavour-3a"
                              class="custom__input inner__checkbox-store"
                            >
                              {{ store.name }}
                            </b-form-checkbox>
                            <small class="text-danger">{{ err[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="overviewOptionValueValidation"
                  class="text-danger"
                >
                  {{ overviewOptionValueError }}
                </small>
              </validation-provider>

              <div v-else-if="overviewSecondStepType === 'budget'">
                <div class="widget__left-sub-content input-block-gap">
                  <validation-provider
                    #default="{ errors }"
                    name="Cost Centre"
                    vid="costCenter"
                    rules="required"
                  >
                    <b-form-group
                      label="Choose a Cost Centre"
                      label-for="cost-centre"
                      class="custom__input mb-0"
                      :state="errors.length > 0 || costCenterValidation ? false : null"
                    >
                      <v-select
                        id="cost-centre"
                        v-model="overviewWidgetObject.costCenter"
                        label="name"
                        placeholder="Select from list"
                        :options="costCenterOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="costCenterValidation"
                        class="text-danger"
                      >
                        {{ costCenterError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div
                  v-if="overviewWidgetObject.costCenter"
                  class="widget__left-sub-content input-block-gap"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Budgetary Period"
                    vid="budgetaryPeriod"
                    rules="required"
                  >
                    <b-form-group
                      label="Choose the Budgetary Period"
                      label-for="budgetary-period"
                      class="custom__input mb-0"
                      :state="errors.length > 0 || budgetaryPeriodValidation ? false : null"
                    >
                      <v-select
                        id="budgetary-period"
                        v-model="overviewWidgetObject.budgetaryPeriod"
                        label="name"
                        placeholder="Select from list"
                        :options="budgetaryPeriodOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="budgetaryPeriodValidation"
                        class="text-danger"
                      >
                        {{ budgetaryPeriodError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div
                  v-if="overviewWidgetObject.budgetaryPeriod"
                  class="widget__left-sub-content input-block-gap"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Expense Category Type"
                    vid="expenseCategoryType"
                    rules="required"
                  >
                    <b-form-group
                      label="Choose the Expense Category Type"
                      label-for="expense-category-type"
                      class="custom__input mb-0"
                      :state="errors.length > 0 || expenseCategoryTypeValidation ? false : null"
                    >
                      <v-select
                        id="expense-category-type"
                        v-model="overviewWidgetObject.expenseCategoryType"
                        label="name"
                        placeholder="Select from list"
                        :options="expenseCategoryTypeOptions"
                        :reduce="index => index.value"
                        :clearable="false"
                        @input="setExpenseCategoryOptions()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="expenseCategoryTypeValidation"
                        class="text-danger"
                      >
                        {{ expenseCategoryTypeError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div
                  v-if="overviewWidgetObject.costCenter && overviewWidgetObject.budgetaryPeriod && overviewWidgetObject.expenseCategoryType"
                  class="widget__sub-content mt-0 mb-1"
                  style="max-height: 292px; overflow-y: scroll;"
                >
                  <div class="budgeting__store-location">
                    <h4>Choose the top 5 Expense Categories to display</h4>
                    <validation-provider
                      #default="{ errors: err }"
                      name="Expense Categories"
                      vid="expenseCategories"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          v-for="ecOption in filteredExpenseCategoryOptions"
                          :key="ecOption._id"
                          v-model="overviewWidgetObject.expenseCategories"
                          :value="ecOption._id"
                          name="flavour-3a"
                          class="custom__input inner__checkbox"
                          @change="selectedExpenseCategoryOptionLengthChecked"
                        >
                          {{ ecOption.name }}
                        </b-form-checkbox>
                        <small class="text-danger">{{ err[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="buttonStatus"
          @click="ok()"
        >
          <feather-icon
            icon="SaveIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Save Widget</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. create overview widget modal -->

    <!-- create my dashboard widget modal -->
    <b-modal
      id="create-my-dashboard-widget-modal"
      ref="create-my-dashboard-widget-modal"
      modal-class="dashboard__create-widget-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Add a Widget to My Dashboard"
      size="sl"
      @cancel="closeCreateMyDashboardWidgetModal"
      @ok="submitCreateMyDashboardWidgetData"
    >
      <b-form @submit.prevent="submitCreateMyDashboardWidgetData">
        <validation-observer ref="myDashboardWidgetCreateForm">
          <b-row class="border rounded">
            <b-col
              md="6"
              class="border-right"
            >
              <div class="widget__step-header">
                <h3>Configuration Fields</h3>
              </div>
              <div class="widget__left-sub-content-wrapper">
                <div class="widget__left-sub-content">
                  <b-form-group
                    label="Widget Name*"
                    label-for="widget-name"
                    class="custom__input mb-0"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Widget Name"
                      vid="widgetName"
                      rules="required"
                    >
                      <b-form-input
                        id="widget-name"
                        v-model="myDashboardWidgetObject.widgetName"
                        placeholder="Enter a widget name"
                        :state="(errors.length > 0 || widgetNameValidation) ? false : null"
                        name="widget-name"
                        class="mb-0"
                        @input="widgetNameValidation == true ? widgetNameValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="widgetNameValidation"
                        class="text-danger"
                      >
                        {{ widgetNameError }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="widget__left-sub-content">
                  <validation-provider
                    #default="{ errors }"
                    name="Sub-Module"
                    vid="subModuleValue"
                    rules="required"
                  >
                    <b-form-group
                      label="Sub-Module*"
                      label-for="sub-module"
                      class="custom__input mb-0"
                      :state="errors.length > 0 || subModuleValueValidation ? false : null"
                    >
                      <v-select
                        id="sub-module"
                        v-model="myDashboardWidgetObject.module"
                        label="name"
                        placeholder="Select a sub-module"
                        :options="subModuleOptions"
                        :reduce="opItem => opItem.value"
                        :selectable="option => !option.hasOwnProperty('category')"
                        :clearable="false"
                        @input="subModuleEvent(myDashboardWidgetObject.module); subModuleValueValidation == true ? (subModuleValueValidation = false) : null"
                      >
                        <template #option="{ category, name }">
                          <div
                            v-if="category"
                            class="group"
                          >
                            {{ category }}
                          </div>
                          {{ name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="subModuleValueValidation"
                        class="text-danger"
                      >
                        {{ subModuleValueError }}
                      </small>
                    </b-form-group>
                  </validation-provider>

                  <div v-if="!isSubModuleEmpty">
                    <div
                      v-if="myDashboardWidgetObject.module == 'Bookings'"
                      class="sub__module-check-options"
                    >
                      <h4>Booking Status</h4>
                      <b-form-group>
                        <b-form-checkbox
                          v-for="statusOption in bookingStatusOptions"
                          :key="statusOption.value"
                          v-model="myDashboardWidgetObject.statusOptions"
                          :value="statusOption.value"
                          name="flavour-3a"
                          class="radio_custom_input"
                        >
                          {{ statusOption.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>

                    <div v-if="myDashboardWidgetObject.module == 'Service Forms'">
                      <div class="sub__module-check-options">
                        <h4>Select Digital Service Form Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="dsfOption in digitalServiceFormOptions"
                            :key="dsfOption.value"
                            v-model="myDashboardWidgetObject.extraOptions"
                            :value="dsfOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ dsfOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                      <div class="sub__module-check-options">
                        <h4>Select Service Form Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="sfOption in serviceFormStatusOptions"
                            :key="sfOption.value"
                            v-model="myDashboardWidgetObject.statusOptions"
                            :value="sfOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ sfOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="myDashboardWidgetObject.module == 'Service Requests'">
                      <div class="sub__module-check-options">
                        <h4>Select Service Request Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="srOption in serviceRequestStatusOptions"
                            :key="srOption.value"
                            v-model="myDashboardWidgetObject.statusOptions"
                            :value="srOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ srOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                      <div class="sub__module-check-options">
                        <h4>Select Payment Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="psOption in paymentStatusOptions"
                            :key="psOption.value"
                            v-model="myDashboardWidgetObject.extraOptions"
                            :value="psOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ psOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="myDashboardWidgetObject.module == 'Inventory Requests'">
                      <div class="sub__module-check-options">
                        <h4>Select Store Location</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="slOption in inventoryStoreOptions"
                            :key="slOption._id"
                            v-model="myDashboardWidgetObject.extraOptions"
                            :value="slOption._id"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ slOption.name }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                      <div class="sub__module-check-options">
                        <h4>Select Inventory Request Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="irOption in inventoryRequestStatusOptions"
                            :key="irOption.value"
                            v-model="myDashboardWidgetObject.statusOptions"
                            :value="irOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ irOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="myDashboardWidgetObject.module == 'Purchase Requests'">
                      <div class="sub__module-check-options">
                        <h4>Select Type of Request</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="ptrOption in purchaseTypeRequestOptions"
                            :key="ptrOption.value"
                            v-model="myDashboardWidgetObject.extraOptions"
                            :value="ptrOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                            inline
                          >
                            {{ ptrOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                      <div class="sub__module-check-options">
                        <h4>Select Budget Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="pbsOption in purchaseBudgetStatusOptions"
                            :key="pbsOption.value"
                            v-model="myDashboardWidgetObject.statusOptions"
                            :value="pbsOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ pbsOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="myDashboardWidgetObject.module == 'Vendor Payments'">
                      <div class="sub__module-check-options">
                        <h4>Select Type of Request</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="vptOption in purchaseTypeRequestOptions"
                            :key="vptOption.value"
                            v-model="myDashboardWidgetObject.statusOptions"
                            :value="vptOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                            inline
                          >
                            {{ vptOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="myDashboardWidgetObject.module == 'Vendors List'">
                      <div class="sub__module-check-options">
                        <h4>Select Vendor Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="vsOption in vendorStatusOptions"
                            :key="vsOption.value"
                            v-model="myDashboardWidgetObject.statusOptions"
                            :value="vsOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ vsOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="!isSubModuleEmpty"
                  class="widget__left-sub-content"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Filter By"
                    vid="dataFilterBy"
                    rules="required"
                  >
                    <b-form-group
                      label="Filter by*"
                      label-for="filter-by"
                      class="custom__input"
                      :state="errors.length > 0 || dataFilterByValidation ? false : null"
                    >
                      <v-select
                        id="filter-by"
                        v-model="myDashboardWidgetObject.filterBy"
                        label="text"
                        placeholder="Select a filter option"
                        :options="filterByOptions"
                        :reduce="(text) => text.value"
                        :clearable="false"
                        @input="dataFilterByValidation == true ? (dataFilterByValidation = false) : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="dataFilterByValidation"
                        class="text-danger"
                      >
                        {{ dataFilterByError }}
                      </small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="Data freshness based on"
                    vid="dataFreshness"
                    rules="required"
                  >
                    <b-form-group
                      label="Data freshness based on*"
                      label-for="data-freshness-based-on"
                      class="custom__input"
                      :state="errors.length > 0 || dataFreshnessValidation ? false : null"
                    >
                      <v-select
                        id="data-freshness-based-on"
                        v-model="myDashboardWidgetObject.dataFreshness"
                        label="text"
                        placeholder="Select data date"
                        :options="dataFreshnessOptions"
                        :reduce="(text) => text.value"
                        :clearable="false"
                        @input="dataFreshnessValidation == true ? (dataFreshnessValidation = false) : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="dataFreshnessValidation"
                        class="text-danger"
                      >
                        {{ dataFreshnessError }}
                      </small>
                    </b-form-group>
                  </validation-provider>

                  <div class="data__freshness-block">
                    <h5>Data range (Appointment Time)*</h5>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Data Range"
                        vid="dataRangeTime"
                        rules="required"
                      >
                        <b-form-checkbox
                          v-model="myDashboardWidgetObject.dataRangeTime"
                          value="today"
                          name="flavour-3a"
                          class="radio_custom_input"
                        >
                          Today
                        </b-form-checkbox>
                        <div class="d-flex mb-1">
                          <b-form-checkbox
                            v-model="myDashboardWidgetObject.dataRangeTime"
                            value="past"
                            name="flavour-3a"
                            class="radio_custom_input radio_with_select_input"
                          />
                          <v-select
                            id="day-past"
                            v-model="myDashboardWidgetObject.dayPast"
                            class="mr-1"
                            :options="dateRange"
                            :clearable="false"
                            @input="statusValidation == true ? (statusValidation = false) : null"
                          />
                          <span class="my-auto">days in the past</span>
                        </div>

                        <div class="d-flex mb-1">
                          <b-form-checkbox
                            v-model="myDashboardWidgetObject.dataRangeTime"
                            value="future"
                            name="flavour-3a"
                            class="radio_custom_input radio_with_select_input"
                          />
                          <v-select
                            id="day-future"
                            v-model="myDashboardWidgetObject.dayFuture"
                            class="mr-1"
                            :options="dateRange"
                            :clearable="false"
                            @input="statusValidation == true ? (statusValidation = false) : null"
                          />
                          <span class="my-auto">days in the future</span>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="dataRangeTimeValidation"
                          class="text-danger"
                        >
                          {{ dataRangeTimeError }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col
              v-if="isSubModuleEmpty"
              md="6"
              class="widget__second-step"
            >
              <div class="widget__second-step-only-text">
                <p>Please select a module data in step 1 to do configuration</p>
              </div>
            </b-col>

            <b-col
              v-if="!isSubModuleEmpty"
              md="6"
            >
              <div class="widget__step-header">
                <h3>Columns to Display</h3>
              </div>

              <div
                class="widget__sub-content"
              >
                <div class="budgeting__store-location">
                  <h4>Select data column to display</h4>
                  <p>It is recommended to display not more than 5 columns</p>
                  <b-form-group>
                    <b-form-checkbox
                      v-for="tableCol in selectableTableColumns"
                      :key="tableCol.key"
                      v-model="myDashboardWidgetObject.tableColumns"
                      :value="tableCol.key"
                      name="flavour-3a"
                      class="custom__input inner__checkbox"
                      @change="myDashboardColumnLengthChecked"
                    >
                      {{ tableCol.label }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="buttonStatus"
          @click="ok()"
        >
          <feather-icon
            icon="SaveIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Save Widget</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. create my dashboard widget modal -->

    <!-- create department dashboard widget modal -->
    <b-modal
      id="create-department-dashboard-widget-modal"
      ref="create-department-dashboard-widget-modal"
      modal-class="dashboard__create-widget-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Add a Widget to Department Dashboard"
      size="ml"
      @cancel="closeCreateDepartmentDashboardWidgetModal"
      @ok="submitCreateDepartmentDashboardWidgetData"
    >
      <b-form @submit.prevent="submitCreateDepartmentDashboardWidgetData">
        <validation-observer ref="departmentDashboardWidgetCreateForm">
          <b-row class="border rounded">
            <b-col
              md="6"
              class="border-right"
            >
              <div class="widget__step-header">
                <h3>Configuration Fields</h3>
              </div>
              <div class="widget__left-sub-content-wrapper">
                <div class="widget__left-sub-content">
                  <validation-provider
                    #default="{ errors }"
                    name="Sub-Module"
                    vid="subModuleValue"
                    rules="required"
                  >
                    <b-form-group
                      label="Sub-Module*"
                      label-for="sub-module"
                      class="custom__input mb-0"
                      :state="errors.length > 0 || subModuleValueValidation ? false : null"
                    >
                      <v-select
                        id="sub-module"
                        v-model="departmentWidgetObject.module"
                        label="name"
                        placeholder="Select a sub-module"
                        :options="subModuleOptions"
                        :reduce="opItem => opItem.value"
                        :selectable="option => !option.hasOwnProperty('category')"
                        :clearable="false"
                        @input="subModuleEventDepartment(departmentWidgetObject.module); subModuleValueValidation == true ? (subModuleValueValidation = false) : null"
                      >
                        <template #option="{ category, name }">
                          <div
                            v-if="category"
                            class="group"
                          >
                            {{ category }}
                          </div>
                          {{ name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="subModuleValueValidation"
                        class="text-danger"
                      >
                        {{ subModuleValueError }}
                      </small>
                    </b-form-group>
                  </validation-provider>

                  <div v-if="!isSubModuleEmpty">
                    <div
                      v-if="departmentWidgetObject.module == 'Bookings'"
                      class="sub__module-check-options sm-padding"
                    >
                      <div class="widget-helper">
                        <span>Bookings assigned to people belonging to current user’s assigned department(s)</span>
                      </div>
                      <h4>Booking Status</h4>
                      <b-form-group>
                        <b-form-checkbox
                          v-for="statusOption in bookingStatusOptions"
                          :key="statusOption.value"
                          v-model="departmentWidgetObject.statusOptions"
                          :value="statusOption.value"
                          name="flavour-3a"
                          class="radio_custom_input"
                        >
                          {{ statusOption.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>

                    <div v-if="departmentWidgetObject.module == 'Service Forms'">
                      <div class="sub__module-check-options sm-padding">
                        <div class="widget-helper">
                          <span>Service forms assigned to users belonging to current user’s assigned department(s)</span>
                        </div>
                        <h4>Select Service Form Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="sfOption in serviceFormStatusOptions"
                            :key="sfOption.value"
                            v-model="departmentWidgetObject.statusOptions"
                            :value="sfOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ sfOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                      <div class="sub__module-check-options">
                        <h4>Select Payment Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="psOption in paymentStatusOptions"
                            :key="psOption.value"
                            v-model="departmentWidgetObject.extraOptions"
                            :value="psOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ psOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="departmentWidgetObject.module == 'Service Requests'">
                      <div class="sub__module-check-options sm-padding">
                        <div class="widget-helper">
                          <span>Service Requests assigned to users belonging to current user’s assigned department(s)</span>
                        </div>
                        <h4>Select Service Request Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="srOption in serviceRequestStatusOptions"
                            :key="srOption.value"
                            v-model="departmentWidgetObject.statusOptions"
                            :value="srOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ srOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                      <div class="sub__module-check-options">
                        <h4>Select Payment Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="psOption in paymentStatusOptions"
                            :key="psOption.value"
                            v-model="departmentWidgetObject.extraOptions"
                            :value="psOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ psOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="departmentWidgetObject.module == 'Inventory Requests'">
                      <div class="sub__module-check-options sm-padding">
                        <div class="widget-helper">
                          <span>Inventory Requests for stores managed by users belonging to current user’s assigned department(s)</span>
                        </div>
                        <h4>Select Inventory Request Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="irOption in inventoryRequestStatusOptions"
                            :key="irOption.value"
                            v-model="departmentWidgetObject.statusOptions"
                            :value="irOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ irOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="departmentWidgetObject.module == 'Purchase Requests'">
                      <div class="sub__module-check-options sm-padding">
                        <div class="widget-helper">
                          <p class="mb-0">
                            Department dashboard data will be based on:
                          </p>
                          <p class="mt-1 mb-0">
                            i. Purchase Requests tagged to current user’s assigned department(s) or
                          </p>
                          <p class="mb-0">
                            ii. Purchase Requests for assigned to users belonging to current user’s assigned department(s)
                          </p>
                        </div>
                        <h4>Select Type of Request</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="ptrOption in purchaseTypeRequestOptions"
                            :key="ptrOption.value"
                            v-model="departmentWidgetObject.extraOptions"
                            :value="ptrOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                            inline
                          >
                            {{ ptrOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                      <div class="sub__module-check-options">
                        <h4>Select Budget Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="pbsOption in purchaseBudgetStatusOptions"
                            :key="pbsOption.value"
                            v-model="departmentWidgetObject.statusOptions"
                            :value="pbsOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ pbsOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="departmentWidgetObject.module == 'Vendor Payments'">
                      <div class="sub__module-check-options sm-padding">
                        <div class="widget-helper">
                          <p class="mb-0">
                            Department dashboard data will be based on:
                          </p>
                          <p class="mt-1 mb-0">
                            i.Vendor Payments relating PRs that is tagged to current user’s assigned department(s)
                          </p>
                          <p class="mb-0 ml-75 dot">
                            <span />Not requiring status filter at the moment, can use Data Range to limit data.
                          </p>
                          <p class="mb-0">
                            ii. Vendor Payments that is pending Checks by CP.
                          </p>
                        </div>
                        <h4>Select Type of Request</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="vptOption in purchaseTypeRequestOptions"
                            :key="vptOption.value"
                            v-model="departmentWidgetObject.statusOptions"
                            :value="vptOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                            inline
                          >
                            {{ vptOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="departmentWidgetObject.module == 'Vendors List'">
                      <div class="sub__module-check-options sm-padding">
                        <div class="widget-helper">
                          <p class="mb-0">
                            Department dashboard data will be based on:
                          </p>
                          <p class="mt-1 mb-0">
                            i. Vendor Lists that have statuses of Pending, Incoming and
                          </p>
                          <p class="mb-0">
                            ii. To be able to view the widget, viewing user must have a role with Vendor module’s permission: Vendors List View.
                          </p>
                        </div>
                        <h4>Select Vendor Status</h4>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="vsOption in vendorStatusOptions"
                            :key="vsOption.value"
                            v-model="departmentWidgetObject.statusOptions"
                            :value="vsOption.value"
                            name="flavour-3a"
                            class="radio_custom_input"
                          >
                            {{ vsOption.label }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="!isSubModuleEmpty"
                  class="widget__left-sub-content"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Data freshness based on"
                    vid="dataFreshness"
                    rules="required"
                  >
                    <b-form-group
                      label="Data freshness based on*"
                      label-for="data-freshness-based-on"
                      class="custom__input"
                      :state="errors.length > 0 || dataFreshnessValidation ? false : null"
                    >
                      <v-select
                        id="data-freshness-based-on"
                        v-model="departmentWidgetObject.dataFreshness"
                        label="text"
                        placeholder="Select data date"
                        :options="dataFreshnessOptions"
                        :reduce="(text) => text.value"
                        :clearable="false"
                        @input="dataFreshnessValidation == true ? (dataFreshnessValidation = false) : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="dataFreshnessValidation"
                        class="text-danger"
                      >
                        {{ dataFreshnessError }}
                      </small>
                    </b-form-group>
                  </validation-provider>

                  <div class="data__freshness-block">
                    <h5>Data range (Appointment Time)*</h5>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Data Range"
                        vid="dataRangeTime"
                        rules="required"
                      >
                        <b-form-checkbox
                          v-model="departmentWidgetObject.dataRangeTime"
                          value="today"
                          name="flavour-3a"
                          class="radio_custom_input"
                        >
                          Today
                        </b-form-checkbox>
                        <div class="d-flex mb-1">
                          <b-form-checkbox
                            v-model="departmentWidgetObject.dataRangeTime"
                            value="past"
                            name="flavour-3a"
                            class="radio_custom_input radio_with_select_input"
                          />
                          <v-select
                            id="day-past"
                            v-model="departmentWidgetObject.dayPast"
                            class="mr-1"
                            :options="dateRange"
                            :clearable="false"
                            @input="statusValidation == true ? (statusValidation = false) : null"
                          />
                          <span class="my-auto">days in the past</span>
                        </div>

                        <div class="d-flex mb-1">
                          <b-form-checkbox
                            v-model="departmentWidgetObject.dataRangeTime"
                            value="future"
                            name="flavour-3a"
                            class="radio_custom_input radio_with_select_input"
                          />
                          <v-select
                            id="day-future"
                            v-model="departmentWidgetObject.dayFuture"
                            class="mr-1"
                            :options="dateRange"
                            :clearable="false"
                            @input="statusValidation == true ? (statusValidation = false) : null"
                          />
                          <span class="my-auto">days in the future</span>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="dataRangeTimeValidation"
                          class="text-danger"
                        >
                          {{ dataRangeTimeError }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col
              v-if="isSubModuleEmpty"
              md="6"
              class="widget__second-step"
            >
              <div class="widget__second-step-only-text">
                <p>Please select a module data in step 1 to do configuration</p>
              </div>
            </b-col>

            <b-col
              v-if="!isSubModuleEmpty"
              md="6"
            >
              <div class="widget__step-header">
                <h3>Columns to Display</h3>
              </div>

              <div
                class="widget__sub-content"
              >
                <div class="budgeting__store-location">
                  <h4>Select data column to display</h4>
                  <p>It is recommended to display not more than 5 columns</p>
                  <b-form-group>
                    <b-form-checkbox
                      v-for="tableCol in selectableTableColumns"
                      :key="tableCol.key"
                      v-model="departmentWidgetObject.tableColumns"
                      :value="tableCol.key"
                      name="flavour-3a"
                      class="custom__input inner__checkbox"
                    >
                      {{ tableCol.label }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="buttonStatus"
          @click="ok()"
        >
          <feather-icon
            icon="SaveIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Save Widget</span>
        </b-button>
      </template>
    </b-modal>
    <!--  /. create department dashboard widget modal -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BNav,
  BFormRadioGroup,
  BFormRadio,
  // BFormCheckboxGroup,
  BFormCheckbox,
  BTabs,
  BTab,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import store from '@/store/index'
import {
  // eslint-disable-next-line import/named
  required,
  between,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContentCustom from './ToastificationContentCustom.vue'
import MonthlyBookingCounts from '../widgets/overview/MonthlyBookingCounts.vue'
import DailyBookingCounts from '../widgets/overview/DailyBookingCounts.vue'
import ServiceFormStatusCount from '../widgets/overview/ServiceFormStatusCount.vue'
import ServiceFormAgeCount from '../widgets/overview/ServiceFormAgeCount.vue'
import ServiceRequestThirtyDays from '../widgets/overview/ServiceRequestThirtyDays.vue'
import ServiceRequestToday from '../widgets/overview/ServiceRequestToday.vue'
import ServiceRequestAgeCount from '../widgets/overview/ServiceRequestAgeCount.vue'
import ServiceRequestQuarterlyPayment from '../widgets/overview/ServiceRequestQuarterlyPayment.vue'
import ServiceRequestAwaitingApproval from '../widgets/overview/ServiceRequestAwaitingApproval.vue'
import InventoryRequestsByStore from '../widgets/overview/InventoryRequestsByStore.vue'
import InventoryRequestsByStatus from '../widgets/overview/InventoryRequestsByStatus.vue'
import PurchaseRequestCount from '../widgets/overview/PurchaseRequestCount.vue'
import QuotationCount from '../widgets/overview/QuotationCount.vue'
import PurchaseOrderCount from '../widgets/overview/PurchaseOrderCount.vue'
import BudgetOverview from '../widgets/overview/BudgetOverview.vue'
import BookingsList from '../widgets/my-dashboard/BookingsList.vue'
import InventoryRequestList from '../widgets/my-dashboard/InventoryRequestList.vue'
import PurchaseRequestList from '../widgets/my-dashboard/PurchaseRequestList.vue'
import ServiceFormList from '../widgets/my-dashboard/ServiceFormList.vue'
import ServiceRequestList from '../widgets/my-dashboard/ServiceRequestList.vue'
import VendorList from '../widgets/my-dashboard/VendorList.vue'
import VendorPaymentList from '../widgets/my-dashboard/VendorPaymentList.vue'
import DepartmentBookingsList from '../widgets/department-dashboard/BookingsList.vue'
import DepartmentInventoryRequestList from '../widgets/department-dashboard/InventoryRequestList.vue'
import DepartmentPurchaseRequestList from '../widgets/department-dashboard/PurchaseRequestList.vue'
import DepartmentServiceFormList from '../widgets/department-dashboard/ServiceFormList.vue'
import DepartmentServiceRequestList from '../widgets/department-dashboard/ServiceRequestList.vue'
import DepartmentVendorList from '../widgets/department-dashboard/VendorList.vue'
import DepartmentVendorPaymentList from '../widgets/department-dashboard/VendorPaymentList.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BTabs,
    BTab,
    BAvatar,
    BDropdown,
    BDropdownItem,
    AppCollapse,
    AppCollapseItem,

    vSelect,
    draggable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    ValidationProvider,
    ValidationObserver,
    MonthlyBookingCounts,
    DailyBookingCounts,
    ServiceFormStatusCount,
    ServiceFormAgeCount,
    ServiceRequestThirtyDays,
    ServiceRequestToday,
    ServiceRequestAgeCount,
    ServiceRequestQuarterlyPayment,
    ServiceRequestAwaitingApproval,
    InventoryRequestsByStore,
    InventoryRequestsByStatus,
    PurchaseRequestCount,
    QuotationCount,
    PurchaseOrderCount,
    BudgetOverview,
    BookingsList,
    InventoryRequestList,
    PurchaseRequestList,
    ServiceFormList,
    ServiceRequestList,
    VendorList,
    VendorPaymentList,
    DepartmentBookingsList,
    DepartmentInventoryRequestList,
    DepartmentPurchaseRequestList,
    DepartmentServiceFormList,
    DepartmentServiceRequestList,
    DepartmentVendorList,
    DepartmentVendorPaymentList,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // validation
      required,
      between,

      // data properties
      departmentSubTab: 0,
      bookingsCount: 0,
      serviceFormsCount: 0,
      serviceRequestsCount: 0,
      inventoryRequestsCount: 0,
      purchaseRequestsCount: 0,
      vendorPaymentsCount: 0,
      vendorsCount: 0,
      stepTwoEmpty: false,
      departmentDashboardStepTwoEmpty: true,
      subModuleCheckbox: [],
      appointmentLeftOptionValue: [],
      operationLeftOptionValue: [],
      budgetingStoreLocationOptionValue: [],
      isSubModuleEmpty: true,
      dayPast: '10',
      dayFuture: '10',
      buttonStatus: false,
      savedData: false,
      viewName: '',
      description: '',
      departments: [],
      users: [],
      overviewWidgets: [],
      myDashboardWidgets: [],
      departmentWidgets: [],
      overviewWidgetObject: {},
      myDashboardWidgetObject: {},
      departmentWidgetObject: {},
      overviewSecondStepOptions: [],
      overviewSecondStepType: '',
      overviewEditIndex: null,
      overviewEditing: false,
      myDashboardEditIndex: null,
      myDashboardEditing: false,
      departmentDashboardEditIndex: null,
      departmentDashboardEditing: false,
      status: 'active',
      nameError: 'Valid name is required',
      nameValidation: false,
      departmentError: 'Valid category is required',
      departmentValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      usersError: '',
      usersValidation: false,
      // widgetName: '',
      widgetNameError: 'Valid widget name is required',
      widgetNameValidation: false,
      subModuleValue: '',
      subModuleValueError: 'Valid sub-module is required',
      subModuleValueValidation: false,
      // dataFilterBy: '',
      dataFilterByError: 'Valid filter by is required',
      dataFilterByValidation: false,
      // dataFreshness: '',
      dataFreshnessError: 'Valid data freshness based on is required',
      dataFreshnessValidation: false,
      // dataRangeTime: '',
      dataRangeTimeError: 'Valid date range time is required',
      dataRangeTimeValidation: false,
      costCenter: '',
      costCenterValidation: false,
      costCenterError: 'Valid cost center is required',
      budgetaryPeriod: '',
      budgetaryPeriodValidation: false,
      budgetaryPeriodError: 'Valid cost center is required',
      expenseCategoryType: '',
      expenseCategoryTypeValidation: false,
      expenseCategoryTypeError: 'Valid cost center is required',
      overviewOptionValue: '',
      overviewOptionValueValidation: false,
      overviewOptionValueError: 'Valid value is required',
      bookingValue: [],
      digitalServiceFormValue: [],
      serviceFormValue: [],
      serviceRequestValue: [],
      paymentValue: [],
      storeLocationValue: [],
      inventoryRequestValue: [],
      displayColumnValue: [],
      expenseCategoryValue: [],
      yearOptions: [],
      // option array of objects
      statusOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      overviewBookingOptions: [
        { text: 'Current month: Booking Activity', value: 'Current month: Booking Activity', description: 'Show total counts of bookings per activity type, current monthly.' },
        { text: 'Today only: Booking Status', value: 'Today only: Booking Status', description: 'Show total counts of booking status, today only.' },
      ],
      overviewServiceFormOptions: [
        { text: 'Digital Service Form status', value: 'Digital Service Form status', description: "Shows total counts of digital SF in 'Signing' and 'Ready to sign' status" },
        { text: 'Pending Status Aging Days', value: 'Pending Status Aging Days', description: 'Shows total counts of SF in Pending status sort by aging days' },
      ],
      overviewServiceRequestOptions: [
        { text: 'Last 30D: Pending status', value: 'Last 30D: Pending status', description: 'Shows total counts of SR in ‘Pending’ status, within the last 30D' },
        { text: 'Today only: Pending status', value: 'Today only: Pending status', description: 'Shows total counts of SR in ‘Pending’ status, today only' },
        { text: 'Pending Status Aging Days', value: 'Pending Status Aging Days', description: 'Shows total counts of SR in Pending status sort by aging days' },
        { text: 'Quarterly Payment Status', value: 'Quarterly Payment Status', description: 'Shows total count of SR payment status (Unpaid, FSP, Partially Paid and Paid)' },
        { text: 'Awaiting Approval Status', value: 'Awaiting Approval Status', description: 'Shows total count of the SR’s Awaiting Approval status ' },
      ],
      overviewInventoryRequestOptions: [
        { text: 'Stores', value: 'Stores', description: "Shows total counts of inventory items of the selected stores in 'Open' Status" },
        { text: 'Status', value: 'Status', description: "Shows total counts of the selected store's inventory items based on 'Open', 'Collected', 'Returned' and 'Cancelled' statuses" },
      ],
      overviewPurchaseRequestOptions: [
        { text: 'PRF', value: 'PRF', description: 'Shows total counts of PRF in ‘Pending’ status by aging days' },
        { text: 'QCF', value: 'QCF', description: 'Shows total counts of QCF in ‘Pending’ status by aging days' },
        { text: 'PO', value: 'PO', description: 'Shows total counts of PO in ‘Pending’ status by aging days' },
      ],
      userOptions: [],
      computedUserOption: [],
      departmentOptions: [],
      appointmentLeftOptions: [
        { label: 'Bookings', value: 'bookings' },
      ],
      operationsLeftOptions: [
        { label: 'Service Forms', value: 'service forms' },
        { label: 'Service Requests', value: 'service requests' },
      ],
      inventoryLeftOptions: [
        { label: 'Inventory Requests', value: 'inventory requests' },
      ],
      purchasingLeftOptions: [
        { label: 'Purchase Requests', value: 'purchase requests' },
      ],
      budgetingLeftOptions: [
        { label: 'Budget Overview', value: 'budget overview' },
      ],
      inventoryStoreOptions: [],
      subModuleOptions: [
        { category: 'Appointments', name: '', value: 'Appointments' },
        { name: 'Bookings', value: 'Bookings' },

        { category: 'Operations', name: '', value: 'Operations' },
        { name: 'Service Forms', value: 'Service Forms' },
        { name: 'Service Requests', value: 'Service Requests' },

        { category: 'Inventory', name: '', value: 'Inventory' },
        { name: 'Inventory Requests', value: 'Inventory Requests' },

        { category: 'Purchasing', name: '', value: 'Purchasing' },
        { name: 'Purchase Requests', value: 'Purchase Requests' },
        { name: 'Vendor Payments', value: 'Vendor Payments' },
        { name: 'Vendors List', value: 'Vendors List' },
      ],
      bookingStatusOptions: [
        { label: 'Confirmed', value: 'confirmed' },
        { label: 'Waiting List', value: 'waiting-list' },
        { label: 'Pending Payment', value: 'pending-payment' },
        { label: 'Draft', value: 'draft' },
        { label: 'Pending Approval', value: 'pending-approval' },
        { label: 'Cancelled', value: 'cancelled' },
      ],
      digitalServiceFormOptions: [
        { label: 'Signing', value: 'Signing' },
        { label: 'Ready to Sign', value: 'Ready to sign' },
      ],
      serviceFormStatusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Completed', value: 'completed' },
        { label: 'Awaiting Approval', value: 'awaiting approval' },
        { label: 'Completed and Paid', value: 'completed and paid' },
      ],
      serviceRequestStatusOptions: [
        { label: 'Awaiting Approval', value: 'awaiting approval' },
        { label: 'Unassigned', value: 'unassigned' },
        { label: 'Pending', value: 'pending' },
      ],
      paymentStatusOptions: [
        { label: 'Paid', value: 'Paid' },
        { label: 'FSP', value: 'FSP' },
        { label: 'Partially Paid', value: 'Partially Paid' },
        { label: 'N/A', value: 'N/A' },
        { label: 'Unpaid', value: 'Unpaid' },
      ],
      inventoryRequestStatusOptions: [
        { label: 'Open', value: 'open' },
        { label: 'Returned', value: 'returned' },
        { label: 'Collected', value: 'collected' },
        { label: 'Completed', value: 'completed' },
        { label: 'Cancelled', value: 'cancelled' },
      ],
      purchaseTypeRequestOptions: [
        { label: 'Purchase Requisition Form (PRF)', value: 'Purchase Requisition Form (PRF)' },
        { label: 'CAPEX', value: 'Capital Expenditure Requisition Form (CAPEX)' },
      ],
      purchaseBudgetStatusOptions: [
        { label: 'Allocated', value: 'allocated' },
        { label: 'Over-allocated', value: 'over-allocated' },
        { label: 'Not Allocated', value: 'not-allocated' },
        { label: 'Under-allocated', value: 'under-allocated' },
      ],
      vendorPaymentTypeRequestOptions: [
        { label: 'Purchase Requisition Form (PRF)', value: 'Purchase Requisition Form (PRF)' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Approved', value: 'Approved' },
        { label: 'Draft', value: 'Draft' },
      ],
      vendorStatusOptions: [
        { label: 'Incoming', value: 'incoming' },
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Draft', value: 'draft' },
      ],
      costCenterOptions: [],
      budgetaryPeriodOptions: [],
      expenseCategoryTypeOptions: [
        { name: 'Operating Expenses', value: 'Operating Expenses' },
        { name: 'CAPEX (Fixed Assets)', value: 'CAPEX (Fixed Assets)' },
        { name: 'CAPEX (Lease Assets)', value: 'CAPEX (Lease Assets)' },
        { name: 'Projects', value: 'Projects' },
        { name: 'Non-budgeted', value: 'Non-budgeted' },
      ],
      expenseCategoryOptions: [],
      filteredExpenseCategoryOptions: [],
      filterByOptions: [],
      dataFreshnessOptions: [],
      bookingColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'start',
          label: 'Appointment Time',
          sortable: true,
        },
        {
          key: 'activityType',
          label: 'Activity Type',
          sortable: true,
        },
        {
          key: 'remarks',
          label: 'Remarks',
          sortable: true,
        },
        {
          key: 'customerName',
          label: 'Customer',
          sortable: true,
        },
        {
          key: 'agentName',
          label: 'Agent',
          sortable: true,
        },
        {
          key: 'createdBy',
          label: 'Created by',
          sortable: true,
        },
        { key: 'status', label: 'Status', sortable: true },
        {
          key: 'freshness',
          label: 'Freshness (Appointment Time)',
          sortable: true,
        },
      ],

      serviceFormsColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'customerName',
          label: 'Applicant',
          sortable: true,
        },
        {
          key: 'agentName',
          label: 'Agent',
          sortable: true,
        },
        // {
        //   key: 'paymentRemarks',
        //   label: 'Payment details',
        //   sortable: true,
        // },
        {
          key: 'createdAt',
          label: 'Created By',
          sortable: true,
        },
        {
          key: 'dutyOfficer.name',
          label: 'Duty Officer',
          sortable: true,
        },
        {
          key: 'status',
          label: 'SF Status',
          sortable: true,
        },
        {
          key: 'digitalSFStatus',
          label: 'Digital SF Status',
          sortable: true,
        },
        {
          key: 'freshness',
          label: 'Freshness (Created By)',
          sortable: true,
        },
      ],

      serviceRequestsColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'workFlow',
          label: 'Request Details',
          sortable: true,
        },
        {
          key: 'deceased',
          label: 'Deceased Details',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Service Date',
          sortable: true,
        },
        {
          key: 'location',
          label: 'Location & Time',
          sortable: true,
        },
        {
          key: 'serviceDescriptionValues',
          label: 'Service descriptions',
          sortable: false,
          thStyle: { width: '310px', minWidth: '310px' },
        },
        {
          key: 'remarks',
          label: 'Remarks',
          sortable: false,
        },
        {
          key: 'agentName',
          label: 'Agent',
          sortable: true,
        },
        {
          key: 'customerName',
          label: 'Customer',
          sortable: true,
        },
        {
          key: 'assignees',
          label: 'Assigned To',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'paymentStatus',
          label: 'Payment Status',
          sortable: true,
        },
        {
          key: 'freshness',
          label: 'Freshness (Service Date)',
          sortable: true,
        },
      ],

      inventoryRequestColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'createdBy',
          label: 'Requester',
          sortable: true,
        },
        {
          key: 'serviceDate',
          label: 'Service request Date/time',
          sortable: true,
        },
        {
          key: 'customerName',
          label: 'Customer Name/File No.',
          sortable: true,
        },
        {
          key: 'stores',
          label: 'Store(s)',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'freshness',
          label: 'Freshness (Service Request Date / Time)',
          sortable: true,
        },
      ],

      purchaseRequestColumns: [
        {
          key: 'caseID',
          label: 'Case number',
          sortable: true,
        },
        {
          key: 'receivedDate',
          label: 'PRF Received On',
          sortable: true,
        },
        {
          key: 'purpose',
          label: 'Justification',
        },
        {
          key: 'requestedBy',
          label: 'Requester',
        },
        {
          key: 'vendor',
          label: 'vendor',
          sortable: true,
        },
        {
          key: 'company',
          label: 'Entity',
          sortable: true,
        },
        {
          key: 'invoices',
          label: 'Invoice ID',
          sortable: false,
        },
        {
          key: 'pendingSignatory',
          label: 'Pending Signatory',
          sortable: false,
        },
        {
          key: 'assignedTo',
          label: 'Assigned to',
          sortable: true,
        },
        {
          key: 'stage',
          label: 'Stage',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'requestType',
          label: 'Type of Request',
          sortable: true,
        },
        {
          key: 'budgetStatus',
          label: 'Budget Status',
          sortable: true,
        },
        {
          key: 'freshness',
          label: 'Freshness (Created On)',
          sortable: true,
        },
      ],

      vendorPaymentColumns: [
        {
          key: 'invoiceID',
          label: 'Invoice ID',
          sortable: true,
        },
        {
          key: 'prCaseID',
          label: 'Case Number',
          sortable: true,
        },
        {
          key: 'vendorName',
          label: 'Vendor',
          sortable: true,
        },
        {
          key: 'createdBy',
          label: 'Uploaded By',
          sortable: false,
        },
        {
          key: 'deptCheckStatus',
          label: 'Checks by Dept/HQ',
          sortable: true,
        },
        {
          key: 'cpCheckStatus',
          label: 'Checks by CP',
          sortable: true,
        },
        {
          key: 'paymentStatus',
          label: 'Payment Status',
          sortable: true,
        },
        {
          key: 'totalPayable',
          label: 'Payment Details',
          sortable: true,
        },
        {
          key: 'requestType',
          label: 'Type of Request',
          sortable: true,
        },
        {
          key: 'purpose',
          label: 'Justification',
        },
        {
          key: 'freshness',
          label: 'Freshness (Uploaded By)',
          sortable: true,
        },
      ],

      vendorListColumns: [
        {
          key: 'stringID',
          label: 'Vendor ID',
          sortable: true,
        },
        {
          key: 'companyName',
          label: 'Company Name',
          sortable: true,
        },
        {
          key: 'providedServiceType',
          label: 'Nature of Business',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created On',
          sortable: true,
        },
        {
          key: 'lastOrder',
          label: 'Last Purchase Order',
          sortable: true,
        },
        {
          key: 'rank',
          label: 'Ranking',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'freshness',
          label: 'Freshness (Created On)',
          sortable: true,
        },
      ],
      selectableTableColumns: [],
      dateRange: ['10', '30', '60'],
    }
  },

  async beforeMount() {
    const currentYear = new Date().getFullYear()
    // eslint-disable-next-line no-plusplus
    for (let year = process.env.VUE_APP_REPORT_YEAR; year <= currentYear; year++) {
      this.yearOptions.push(year)
    }
    await this.$http.get('user/dashboard-views/respond-with/extra-fields')
      .then(response => {
        this.userOptions = response.data.users || []
        this.departmentOptions = response.data.departments || []
        this.inventoryStoreOptions = response.data.stores || []
        this.costCenterOptions = response.data.costCenters || []
        this.budgetaryPeriodOptions = response.data.allPeriods || []
        this.expenseCategoryOptions = response.data.expenseCategoryOptions || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
                  error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    if (this.$route.query.from) {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const newMeta = {
        active: true,
        text: 'Duplicate Dashboard View',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      this.$http.get(`user/dashboard-views/${this.$route.query.from}`)
        .then(response => {
          this.viewName = (`Copy of ${response.data.name}`) || ''
          this.departments = response.data.departments || []
          this.overviewWidgets = response.data.overview || []
          this.myDashboardWidgets = response.data.myDashboard || []
          this.departmentWidgets = response.data.departmentDashboard || []
          this.users = response.data.users || []
          this.status = response.data.status || ''

          if (this.departments.length) {
            if (this.departments.length === 1) {
              this.computedUserOption = this.userOptions.filter(o => o.department.includes(this.departments[0]))
            } else {
              let uArray = []
              this.departments.forEach(o => {
                const dept = this.departmentOptions.find(d => d._id === o)
                uArray.push({ category: dept.name, name: '', _id: o })
                const deptUsers = this.userOptions.filter(u => u.department.includes(o))
                uArray = uArray.concat(deptUsers.filter(u => !uArray.find(k => k._id === u._id)))
              })

              this.computedUserOption = uArray
            }
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const newMeta = {
        active: true,
        text: 'Create Dashboard View',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
    }
  },

  methods: {
    makeUserOption() {
      if (this.departments.length) {
        if (this.departments.length === 1) {
          this.computedUserOption = this.userOptions.filter(o => o.department.includes(this.departments[0]))
          this.users = this.users.filter(o => this.computedUserOption.find(u => u._id === o))
        } else {
          let uArray = []
          this.departments.forEach(o => {
            const dept = this.departmentOptions.find(d => d._id === o)
            uArray.push({ category: dept.name, name: '', _id: o })
            const deptUsers = this.userOptions.filter(u => u.department.includes(o))
            uArray = uArray.concat(deptUsers.filter(u => !uArray.find(k => k._id === u._id)))
          })

          this.computedUserOption = uArray
          this.users = this.users.filter(o => this.computedUserOption.find(u => u._id === o))
        }
      } else {
        this.users = []
        this.computedUserOption = []
      }
    },
    subModuleEvent(val) {
      if (val) {
        this.displayColumnValue = []
        this.myDashboardWidgetObject.tableColumns = []
        this.isSubModuleEmpty = false
      } else {
        this.isSubModuleEmpty = true
      }

      if (val === 'Bookings') {
        this.filterByOptions = [{ text: 'Bookings assigned to current user', value: 'Bookings assigned to current user' }]
        this.dataFreshnessOptions = [{ text: 'Appointment Time', value: 'Appointment Time' }]
        this.selectableTableColumns = this.bookingColumns
        this.myDashboardWidgetObject.statusOptions = ['confirmed']
        this.myDashboardWidgetObject.extraOptions = []
        this.myDashboardWidgetObject.filterBy = 'Bookings assigned to current user'
        this.myDashboardWidgetObject.dataFreshness = 'Appointment Time'
        this.myDashboardWidgetObject.dataRangeTime = ['today']
        this.myDashboardWidgetObject.dayPast = '10'
        this.myDashboardWidgetObject.dayFuture = '10'
        this.myDashboardWidgetObject.tableColumns = ['stringID', 'start', 'activityType', 'status', 'freshness']
      }

      if (val === 'Service Forms') {
        this.filterByOptions = [
          // { text: 'Service Forms pending current user’s signature', value: 'Service Forms pending current user’s signature' },
          { text: 'Service Forms assigned to current user', value: 'Service Forms assigned to current user' },
        ]
        this.dataFreshnessOptions = [{ text: 'Created At', value: 'Created At' }]
        this.selectableTableColumns = this.serviceFormsColumns
        this.myDashboardWidgetObject.statusOptions = ['pending', 'awaiting approval']
        this.myDashboardWidgetObject.extraOptions = ['Signing', 'Ready to sign']
        this.myDashboardWidgetObject.filterBy = 'Service Forms assigned to current user'
        this.myDashboardWidgetObject.dataFreshness = 'Created At'
        this.myDashboardWidgetObject.dataRangeTime = ['today', 'past']
        this.myDashboardWidgetObject.dayPast = '10'
        this.myDashboardWidgetObject.dayFuture = '10'
        this.myDashboardWidgetObject.tableColumns = ['stringID', 'customerName', 'createdAt', 'digitalSFStatus', 'freshness']
      }

      if (val === 'Service Requests') {
        this.filterByOptions = [
          { text: 'Service Requests assigned to current user', value: 'Service Requests assigned to current user' },
        ]
        this.dataFreshnessOptions = [{ text: 'Service Date', value: 'Service Date' }]
        this.selectableTableColumns = this.serviceRequestsColumns
        this.myDashboardWidgetObject.statusOptions = ['pending', 'awaiting approval']
        this.myDashboardWidgetObject.extraOptions = ['Paid', 'Partially Paid', 'Unpaid']
        this.myDashboardWidgetObject.filterBy = 'Service Requests assigned to current user'
        this.myDashboardWidgetObject.dataFreshness = 'Service Date'
        this.myDashboardWidgetObject.dataRangeTime = ['today']
        this.myDashboardWidgetObject.dayPast = '10'
        this.myDashboardWidgetObject.dayFuture = '10'
        this.myDashboardWidgetObject.tableColumns = ['stringID', 'workFlow', 'date', 'status', 'paymentStatus']
      }

      if (val === 'Inventory Requests') {
        this.filterByOptions = [
          { text: 'Inventory Requests for stores managed by current user', value: 'Inventory Requests for stores managed by current user' },
        ]
        this.dataFreshnessOptions = [{ text: 'Service Request Date / Time', value: 'Service Request Date / Time' }]
        this.selectableTableColumns = this.inventoryRequestColumns
        this.myDashboardWidgetObject.statusOptions = ['open', 'collected']
        this.myDashboardWidgetObject.extraOptions = []
        this.myDashboardWidgetObject.filterBy = 'Inventory Requests for stores managed by current user'
        this.myDashboardWidgetObject.dataFreshness = 'Service Request Date / Time'
        this.myDashboardWidgetObject.dataRangeTime = ['today', 'past']
        this.myDashboardWidgetObject.dayPast = '30'
        this.myDashboardWidgetObject.dayFuture = '10'
        this.myDashboardWidgetObject.tableColumns = ['stringID', 'serviceDate', 'stores', 'status', 'freshness']
      }

      if (val === 'Purchase Requests') {
        this.filterByOptions = [
          { text: 'Purchase Requests pending current user’s signature', value: 'Purchase Requests pending current user’s signature' },
          { text: 'Purchase Requests requested by current user', value: 'Purchase Requests requested by current user' },
          { text: 'Purchase Requests assigned to current user', value: 'Purchase Requests assigned to current user' },
        ]
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
          { text: 'Updated On', value: 'Updated On' },
        ]
        this.selectableTableColumns = this.purchaseRequestColumns
        this.myDashboardWidgetObject.statusOptions = ['allocated', 'over-allocated', 'not-allocated', 'under-allocated']
        this.myDashboardWidgetObject.extraOptions = ['Purchase Requisition Form (PRF)', 'Capital Expenditure Requisition Form (CAPEX)']
        this.myDashboardWidgetObject.filterBy = 'Purchase Requests pending current user’s signature'
        this.myDashboardWidgetObject.dataFreshness = 'Created On'
        this.myDashboardWidgetObject.dataRangeTime = ['today', 'past']
        this.myDashboardWidgetObject.dayPast = '10'
        this.myDashboardWidgetObject.dayFuture = '10'
        this.myDashboardWidgetObject.tableColumns = ['caseID', 'receivedDate', 'purpose', 'requestedBy', 'freshness']
      }

      if (val === 'Vendor Payments') {
        this.filterByOptions = [
          { text: 'Vendor Payments pending current user’s signature', value: 'Vendor Payments pending current user’s signature' },
        ]
        this.dataFreshnessOptions = [
          { text: 'Uploaded By', value: 'Uploaded By' },
          { text: 'Checks by Dept/HQ', value: 'Checks by Dept/HQ' },
          { text: 'Checks by CP', value: 'Checks by CP' },
        ]
        this.selectableTableColumns = this.vendorPaymentColumns
        this.myDashboardWidgetObject.extraOptions = []
        this.myDashboardWidgetObject.statusOptions = ['Purchase Requisition Form (PRF)', 'Capital Expenditure Requisition Form (CAPEX)']
        this.myDashboardWidgetObject.filterBy = 'Vendor Payments pending current user’s signature'
        this.myDashboardWidgetObject.dataFreshness = 'Uploaded By'
        this.myDashboardWidgetObject.dataRangeTime = ['today', 'past']
        this.myDashboardWidgetObject.dayPast = '10'
        this.myDashboardWidgetObject.dayFuture = '10'
        this.myDashboardWidgetObject.tableColumns = ['invoiceID', 'vendorName', 'createdBy', 'totalPayable', 'freshness']
      }

      if (val === 'Vendors List') {
        this.filterByOptions = [
          { text: 'Vendors pending current user’s signature', value: 'Vendors pending current user’s signature' },
        ]
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
        ]
        this.selectableTableColumns = this.vendorListColumns
        this.myDashboardWidgetObject.extraOptions = []
        this.myDashboardWidgetObject.statusOptions = ['incoming', 'pending']
        this.myDashboardWidgetObject.filterBy = 'Vendors pending current user’s signature'
        this.myDashboardWidgetObject.dataFreshness = 'Created On'
        this.myDashboardWidgetObject.dataRangeTime = ['today', 'past']
        this.myDashboardWidgetObject.dayPast = '10'
        this.myDashboardWidgetObject.dayFuture = '10'
        this.myDashboardWidgetObject.tableColumns = ['stringID', 'providedServiceType', 'createdAt', 'status', 'freshness']
      }
    },

    subModuleEventForEdit(val) {
      this.displayColumnValue = []

      if (val === 'Bookings') {
        this.filterByOptions = [{ text: 'Bookings assigned to current user', value: 'Bookings assigned to current user' }]
        this.dataFreshnessOptions = [{ text: 'Appointment Time', value: 'Appointment Time' }]
        this.selectableTableColumns = this.bookingColumns
      } else if (val === 'Service Forms') {
        this.filterByOptions = [
          // { text: 'Service Forms pending current user’s signature', value: 'Service Forms pending current user’s signature' },
          { text: 'Service Forms assigned to current user', value: 'Service Forms assigned to current user' },
        ]
        this.dataFreshnessOptions = [{ text: 'Created At', value: 'Created At' }]
        this.selectableTableColumns = this.serviceFormsColumns
      } else if (val === 'Service Requests') {
        this.filterByOptions = [
          { text: 'Service Requests assigned to current user', value: 'Service Requests assigned to current user' },
        ]
        this.dataFreshnessOptions = [{ text: 'Service Date', value: 'Service Date' }]
        this.selectableTableColumns = this.serviceRequestsColumns
      } else if (val === 'Inventory Requests') {
        this.filterByOptions = [
          { text: 'Inventory Requests for stores managed by current user', value: 'Inventory Requests for stores managed by current user' },
        ]
        this.dataFreshnessOptions = [{ text: 'Service Request Date / Time', value: 'Service Request Date / Time' }]
        this.selectableTableColumns = this.inventoryRequestColumns
      } else if (val === 'Purchase Requests') {
        this.filterByOptions = [
          { text: 'Purchase Requests pending current user’s signature', value: 'Purchase Requests pending current user’s signature' },
          { text: 'Purchase Requests requested by current user', value: 'Purchase Requests requested by current user' },
          { text: 'Purchase Requests assigned to current user', value: 'Purchase Requests assigned to current user' },
        ]
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
          { text: 'Updated On', value: 'Updated On' },
        ]
        this.selectableTableColumns = this.purchaseRequestColumns
      } else if (val === 'Vendor Payments') {
        this.filterByOptions = [
          { text: 'Vendor Payments pending current user’s signature', value: 'Vendor Payments pending current user’s signature' },
        ]
        this.dataFreshnessOptions = [
          { text: 'Uploaded By', value: 'Uploaded By' },
          { text: 'Checks by Dept/HQ', value: 'Checks by Dept/HQ' },
          { text: 'Checks by CP', value: 'Checks by CP' },
        ]
        this.selectableTableColumns = this.vendorPaymentColumns
      } else if (val === 'Vendors List') {
        this.filterByOptions = [
          { text: 'Vendors pending current user’s signature', value: 'Vendors pending current user’s signature' },
        ]
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
        ]
        this.selectableTableColumns = this.vendorListColumns
      }
    },

    subModuleEventDepartment(val) {
      if (val) {
        this.displayColumnValue = []
        this.departmentWidgetObject.tableColumns = []
        this.isSubModuleEmpty = false
      } else {
        this.isSubModuleEmpty = true
      }

      if (val === 'Bookings') {
        this.dataFreshnessOptions = [{ text: 'Appointment Time', value: 'Appointment Time' }]
        this.selectableTableColumns = this.bookingColumns
        this.departmentWidgetObject.statusOptions = ['confirmed', 'waiting-list', 'pending-payment', 'pending-approval']
        this.departmentWidgetObject.extraOptions = []
        this.departmentWidgetObject.dataFreshness = 'Appointment Time'
        this.departmentWidgetObject.dataRangeTime = ['today', 'past', 'future']
        this.departmentWidgetObject.dayPast = '10'
        this.departmentWidgetObject.dayFuture = '10'
        this.departmentWidgetObject.tableColumns = ['stringID', 'start', 'activityType', 'customerName', 'agentName', 'createdBy', 'status', 'freshness']
      }

      if (val === 'Service Forms') {
        this.dataFreshnessOptions = [{ text: 'Created At', value: 'Created At' }]
        this.selectableTableColumns = this.serviceFormsColumns
        this.departmentWidgetObject.statusOptions = ['pending', 'completed', 'awaiting approval']
        this.departmentWidgetObject.extraOptions = ['Paid', 'FSP', 'Partially Paid', 'N/A', 'Unpaid']
        this.departmentWidgetObject.dataFreshness = 'Created At'
        this.departmentWidgetObject.dataRangeTime = ['today', 'past']
        this.departmentWidgetObject.dayPast = '10'
        this.departmentWidgetObject.dayFuture = '10'
        this.departmentWidgetObject.tableColumns = ['stringID', 'customerName', 'createdAt', 'dutyOfficer.name', 'digitalSFStatus', 'freshness']
      }

      if (val === 'Service Requests') {
        this.dataFreshnessOptions = [{ text: 'Service Date', value: 'Service Date' }]
        this.selectableTableColumns = this.serviceRequestsColumns
        this.departmentWidgetObject.statusOptions = ['pending', 'awaiting approval']
        this.departmentWidgetObject.extraOptions = ['Paid', 'FSP', 'Partially Paid', 'N/A', 'Unpaid']
        this.departmentWidgetObject.dataFreshness = 'Service Date'
        this.departmentWidgetObject.dataRangeTime = ['today', 'past', 'future']
        this.departmentWidgetObject.dayPast = '10'
        this.departmentWidgetObject.dayFuture = '10'
        this.departmentWidgetObject.tableColumns = ['stringID', 'workFlow', 'date', 'location', 'assignees', 'status', 'paymentStatus', 'freshness']
      }

      if (val === 'Inventory Requests') {
        this.dataFreshnessOptions = [{ text: 'Service Request Date / Time', value: 'Service Request Date / Time' }]
        this.selectableTableColumns = this.inventoryRequestColumns
        this.departmentWidgetObject.statusOptions = ['open', 'collected']
        this.departmentWidgetObject.extraOptions = []
        this.departmentWidgetObject.dataFreshness = 'Service Request Date / Time'
        this.departmentWidgetObject.dataRangeTime = ['today', 'past', 'future']
        this.departmentWidgetObject.dayPast = '30'
        this.departmentWidgetObject.dayFuture = '10'
        this.departmentWidgetObject.tableColumns = ['stringID', 'createdBy', 'serviceDate', 'stores', 'status', 'freshness']
      }

      if (val === 'Purchase Requests') {
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
          { text: 'Updated On', value: 'Updated On' },
        ]
        this.selectableTableColumns = this.purchaseRequestColumns
        this.departmentWidgetObject.statusOptions = ['allocated', 'over-allocated', 'not-allocated', 'under-allocated']
        this.departmentWidgetObject.extraOptions = ['Purchase Requisition Form (PRF)', 'Capital Expenditure Requisition Form (CAPEX)']
        this.departmentWidgetObject.dataFreshness = 'Created On'
        this.departmentWidgetObject.dataRangeTime = ['today', 'past']
        this.departmentWidgetObject.dayPast = '10'
        this.departmentWidgetObject.dayFuture = '10'
        this.departmentWidgetObject.tableColumns = ['caseID', 'receivedDate', 'company', 'stage', 'status', 'requestType', 'budgetStatus', 'freshness']
      }

      if (val === 'Vendor Payments') {
        this.dataFreshnessOptions = [
          { text: 'Uploaded By', value: 'Uploaded By' },
          { text: 'Checks by Dept/HQ', value: 'Checks by Dept/HQ' },
          { text: 'Checks by CP', value: 'Checks by CP' },
        ]
        this.selectableTableColumns = this.vendorPaymentColumns
        this.departmentWidgetObject.extraOptions = []
        this.departmentWidgetObject.statusOptions = ['Purchase Requisition Form (PRF)', 'Capital Expenditure Requisition Form (CAPEX)']
        this.departmentWidgetObject.dataFreshness = 'Uploaded By'
        this.departmentWidgetObject.dataRangeTime = ['today', 'past']
        this.departmentWidgetObject.dayPast = '10'
        this.departmentWidgetObject.dayFuture = '10'
        this.departmentWidgetObject.tableColumns = ['invoiceID', 'prCaseID', 'vendorName', 'createdBy', 'totalPayable', 'requestType', 'purpose', 'freshness']
      }

      if (val === 'Vendors List') {
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
        ]
        this.selectableTableColumns = this.vendorListColumns
        this.departmentWidgetObject.extraOptions = []
        this.departmentWidgetObject.statusOptions = ['incoming', 'pending', 'approved']
        this.departmentWidgetObject.dataFreshness = 'Created On'
        this.departmentWidgetObject.dataRangeTime = ['today', 'past']
        this.departmentWidgetObject.dayPast = '10'
        this.departmentWidgetObject.dayFuture = '10'
        this.departmentWidgetObject.tableColumns = ['stringID', 'companyName', 'providedServiceType', 'createdAt', 'status', 'freshness']
      }
    },

    subModuleEventDepartmentForEdit(val) {
      this.displayColumnValue = []

      if (val === 'Bookings') {
        this.dataFreshnessOptions = [{ text: 'Appointment Time', value: 'Appointment Time' }]
        this.selectableTableColumns = this.bookingColumns
      } else if (val === 'Service Forms') {
        this.dataFreshnessOptions = [{ text: 'Created At', value: 'Created At' }]
        this.selectableTableColumns = this.serviceFormsColumns
      } else if (val === 'Service Requests') {
        this.dataFreshnessOptions = [{ text: 'Service Date', value: 'Service Date' }]
        this.selectableTableColumns = this.serviceRequestsColumns
      } else if (val === 'Inventory Requests') {
        this.dataFreshnessOptions = [{ text: 'Service Request Date / Time', value: 'Service Request Date / Time' }]
        this.selectableTableColumns = this.inventoryRequestColumns
      } else if (val === 'Purchase Requests') {
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
          { text: 'Updated On', value: 'Updated On' },
        ]
        this.selectableTableColumns = this.purchaseRequestColumns
      } else if (val === 'Vendor Payments') {
        this.dataFreshnessOptions = [
          { text: 'Uploaded By', value: 'Uploaded By' },
          { text: 'Checks by Dept/HQ', value: 'Checks by Dept/HQ' },
          { text: 'Checks by CP', value: 'Checks by CP' },
        ]
        this.selectableTableColumns = this.vendorPaymentColumns
      } else if (val === 'Vendors List') {
        this.dataFreshnessOptions = [
          { text: 'Created On', value: 'Created On' },
        ]
        this.selectableTableColumns = this.vendorListColumns
      }
    },

    myDashboardColumnLengthChecked() {
      if (this.myDashboardWidgetObject.tableColumns.length > 5) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'You can select maximum display 5 columns',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.myDashboardWidgetObject.tableColumns.splice(-1)
      }
    },

    selectedColumnLengthChecked() {
      if (this.displayColumnValue.length > 5) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'You can select maximum display 5 columns',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.displayColumnValue.splice(-1)
      }
    },

    selectedExpenseCategoryOptionLengthChecked() {
      if (this.overviewWidgetObject.expenseCategories.length > 5) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'You can select maximum display 5 columns',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.overviewWidgetObject.expenseCategories.splice(-1)
      }
    },

    submitForm() {
      this.$swal({
        title: 'Proceed saving the new Dashboard View?',
        html: ' Once the dashboard view is saved, it will be made available to the tagged users for viewing.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/upload.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$refs.dashboardViewCreateForm.validate().then(success => {
              if (success) {
                const formData = new FormData()

                formData.append('name', this.viewName)
                formData.append('departments', JSON.stringify(this.departments))
                formData.append('overview', JSON.stringify(this.overviewWidgets))
                formData.append('myDashboard', JSON.stringify(this.myDashboardWidgets))
                formData.append('departmentDashboard', JSON.stringify(this.departmentWidgets))
                formData.append('users', JSON.stringify(this.users))
                formData.append('status', this.status)

                this.$http.post('user/dashboard-views/store', formData)
                  .then(response => {
                    if (response.data.data) {
                      const toastMessage = `<span class="text-bold-black">Dashboard - ${this.viewName}</span> is now successfully published!`
                      this.$toast({
                        component: ToastificationContentCustom,
                        props: {
                          title: 'Dashboard is published',
                          text: toastMessage,
                          icon: 'CheckCircleIcon',
                          variant: 'success',
                        },
                      })
                      this.$router.push({ name: 'dashboard-views-index' })
                    }
                  })
                  .catch(error => {
                    if (error.data.errors) {
                      error.data.errors.forEach(validationError => {
                        if (validationError.param === 'name') {
                          this.nameError = validationError.msg
                          this.nameValidation = true
                        }
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          }
        })
    },

    openOverviewWidgetModal() {
      this.overviewEditing = false
      this.overviewWidgetObject = {
        module: '',
        subModule: '',
      }
      this.$root.$emit('bv::show::modal', 'create-overview-widget-modal', '')
    },

    editOverviewWidget(index) {
      this.overviewEditing = true
      this.overviewEditIndex = index
      this.overviewWidgetObject = JSON.parse(JSON.stringify(this.overviewWidgets[index]))
      this.makeOverviewSecondStepForEdit()
      this.$root.$emit('bv::show::modal', 'create-overview-widget-modal', '')
    },

    deleteOverviewWidget(index) {
      this.$swal({
        title: 'Delete the widget?',
        html: 'The widget will be deleted and you can create a new widget again',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete the Widget',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.overviewWidgets.splice(index, 1)
          }
        })
    },

    makeOverviewSecondStep() {
      if (this.overviewWidgetObject.module === 'bookings') {
        this.overviewSecondStepOptions = this.overviewBookingOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'service forms') {
        this.overviewSecondStepOptions = this.overviewServiceFormOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'service requests') {
        this.overviewSecondStepOptions = this.overviewServiceRequestOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'purchase requests') {
        this.overviewSecondStepOptions = this.overviewPurchaseRequestOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'inventory requests') {
        // this.overviewWidgetObject.year = this.yearOptions.slice(-1)
        // this.overviewWidgetObject.stores = []
        this.$set(this.overviewWidgetObject, 'year', this.yearOptions.slice(-1))
        this.$set(this.overviewWidgetObject, 'stores', [])
        this.overviewSecondStepType = 'inventory'
      } else if (this.overviewWidgetObject.module === 'budget overview') {
        // this.overviewWidgetObject.costCenter = ''
        // this.overviewWidgetObject.budgetaryPeriod = ''
        // this.overviewWidgetObject.expenseCategoryType = ''
        // this.overviewWidgetObject.expenseCategories = []
        this.$set(this.overviewWidgetObject, 'costCenter', '')
        this.$set(this.overviewWidgetObject, 'budgetaryPeriod', '')
        this.$set(this.overviewWidgetObject, 'expenseCategoryType', '')
        this.$set(this.overviewWidgetObject, 'expenseCategories', [])
        this.overviewSecondStepType = 'budget'
      }
    },

    makeOverviewSecondStepForEdit() {
      if (this.overviewWidgetObject.module === 'bookings') {
        this.overviewSecondStepOptions = this.overviewBookingOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'service forms') {
        this.overviewSecondStepOptions = this.overviewServiceFormOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'service requests') {
        this.overviewSecondStepOptions = this.overviewServiceRequestOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'purchase requests') {
        this.overviewSecondStepOptions = this.overviewPurchaseRequestOptions
        this.overviewSecondStepType = 'radio'
      } else if (this.overviewWidgetObject.module === 'inventory requests') {
        this.overviewSecondStepType = 'inventory'
      } else if (this.overviewWidgetObject.module === 'budget overview') {
        this.overviewSecondStepType = 'budget'
        this.filteredExpenseCategoryOptions = this.expenseCategoryOptions.filter(o => o.type === this.overviewWidgetObject.expenseCategoryType)
      }
    },

    setExpenseCategoryOptions() {
      this.overviewWidgetObject.expenseCategories = []
      this.filteredExpenseCategoryOptions = this.expenseCategoryOptions.filter(o => o.type === this.overviewWidgetObject.expenseCategoryType)
    },

    closeCreateWidgetModal() {
      this.$root.$emit('bv::hide::modal', 'create-overview-widget-modal', '')
    },

    submitCreateWidgetData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.overviewWidgetCreateForm.validate().then(success => {
        if (success) {
          if (this.overviewEditing) {
            this.$set(this.overviewWidgets, this.overviewEditIndex, this.overviewWidgetObject)
          } else {
            this.overviewWidgets.push(this.overviewWidgetObject)
          }
          this.closeCreateWidgetModal()
        }
      })
    },

    openMyDashboardWidgetModal() {
      this.myDashboardEditing = false
      this.myDashboardWidgetObject = {
        widgetName: '',
        module: '',
        statusOptions: [],
        extraOptions: [],
        filterBy: '',
        dataFreshness: '',
        dataRangeTime: [],
        dayPast: '10',
        dayFuture: '10',
        tableColumns: [],
      }
      this.isSubModuleEmpty = true
      this.$root.$emit('bv::show::modal', 'create-my-dashboard-widget-modal', '')
    },

    openDepartmentDashboardWidgetModal() {
      this.departmentDashboardEditing = false
      this.departmentWidgetObject = {
        module: '',
        statusOptions: [],
        extraOptions: [],
        dataFreshness: '',
        dataRangeTime: [],
        dayPast: '10',
        dayFuture: '10',
        tableColumns: [],
      }
      this.isSubModuleEmpty = true
      this.$root.$emit('bv::show::modal', 'create-department-dashboard-widget-modal', '')
    },

    editMyDashboardWidget(index) {
      this.myDashboardEditing = true
      this.myDashboardEditIndex = index
      const widgetObject = this.myDashboardWidgets[index]
      const { dateRangeOption, tableColumns } = widgetObject
      const dataRangeTime = []
      let dayPast = '10'
      let dayFuture = '10'
      const singleTableColumns = tableColumns.map(({ key }) => key)
      dateRangeOption.forEach(r => {
        dataRangeTime.push(r.key)
        if (r.key === 'past') {
          dayPast = r.days
        } else if (r.key === 'future') {
          dayFuture = r.days
        }
      })
      this.myDashboardWidgetObject = {
        widgetName: widgetObject.widgetName,
        module: widgetObject.module,
        statusOptions: widgetObject.statusOptions,
        extraOptions: widgetObject.extraOptions,
        filterBy: widgetObject.filterBy,
        dataFreshness: widgetObject.dataFreshness,
        dataRangeTime,
        dayPast,
        dayFuture,
        tableColumns: singleTableColumns,
      }

      this.isSubModuleEmpty = false
      this.subModuleEventForEdit(widgetObject.module)
      this.$root.$emit('bv::show::modal', 'create-my-dashboard-widget-modal', '')
    },

    deleteMyDashboardWidget(index) {
      this.$swal({
        title: 'Delete the widget?',
        html: 'The widget will be deleted and you can create a new widget again',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete the Widget',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.myDashboardWidgets.splice(index, 1)
          }
        })
    },

    dateRangeDaysChecked() {
      if (this.myDashboardWidgetObject.dataRangeTime === 'today') {
        this.myDashboardWidgetObject.days = ''
      } else if (this.myDashboardWidgetObject.dataRangeTime === 'past') {
        this.myDashboardWidgetObject.days = this.dayPast
      } else {
        this.myDashboardWidgetObject.days = this.dayFuture
      }
    },

    submitCreateMyDashboardWidgetData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.myDashboardWidgetCreateForm.validate().then(success => {
        if (success) {
          const colArray = []
          this.selectableTableColumns.forEach(col => {
            if (this.myDashboardWidgetObject.tableColumns.includes(col.key)) {
              colArray.push(col)
            }
          })
          const dateRangeOption = []
          this.myDashboardWidgetObject.dataRangeTime.forEach(o => {
            let days = 0
            if (o === 'past') {
              days = this.myDashboardWidgetObject.dayPast
            } else if (o === 'future') {
              days = this.myDashboardWidgetObject.dayFuture
            }
            const obj = {
              key: o,
              days,
            }

            dateRangeOption.push(obj)
          })
          const widgetObject = {
            widgetName: this.myDashboardWidgetObject.widgetName,
            module: this.myDashboardWidgetObject.module,
            statusOptions: this.myDashboardWidgetObject.statusOptions,
            extraOptions: this.myDashboardWidgetObject.extraOptions,
            filterBy: this.myDashboardWidgetObject.filterBy,
            dataFreshness: this.myDashboardWidgetObject.dataFreshness,
            dateRangeOption,
            tableColumns: colArray,
          }
          if (this.myDashboardEditing) {
            this.$set(this.myDashboardWidgets, this.myDashboardEditIndex, widgetObject)
          } else {
            this.myDashboardWidgets.push(widgetObject)
          }
          this.closeCreateMyDashboardWidgetModal()
        }
      })
    },

    closeCreateMyDashboardWidgetModal() {
      this.$root.$emit('bv::hide::modal', 'create-my-dashboard-widget-modal', '')
    },

    closeCreateDepartmentDashboardWidgetModal() {
      this.$root.$emit('bv::hide::modal', 'create-department-dashboard-widget-modal', '')
    },

    submitCreateDepartmentDashboardWidgetData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.departmentDashboardWidgetCreateForm.validate().then(success => {
        if (success) {
          const colArray = []
          this.selectableTableColumns.forEach(col => {
            if (this.departmentWidgetObject.tableColumns.includes(col.key)) {
              colArray.push(col)
            }
          })
          const dateRangeOption = []
          this.departmentWidgetObject.dataRangeTime.forEach(o => {
            let days = 0
            if (o === 'past') {
              days = this.departmentWidgetObject.dayPast
            } else if (o === 'future') {
              days = this.departmentWidgetObject.dayFuture
            }
            const obj = {
              key: o,
              days,
            }

            dateRangeOption.push(obj)
          })
          const widgetObject = {
            module: this.departmentWidgetObject.module,
            statusOptions: this.departmentWidgetObject.statusOptions,
            extraOptions: this.departmentWidgetObject.extraOptions,
            dataFreshness: this.departmentWidgetObject.dataFreshness,
            dateRangeOption,
            tableColumns: colArray,
          }
          if (this.departmentDashboardEditing) {
            this.$set(this.departmentWidgets, this.departmentDashboardEditIndex, widgetObject)
          } else {
            this.departmentWidgets.push(widgetObject)
            // this.departmentSubTab = this.departmentWidgets.length - 1
          }
          this.closeCreateDepartmentDashboardWidgetModal()
        }
      })
    },

    editDepartmentDashboardWidget(index) {
      this.departmentDashboardEditing = true
      this.departmentDashboardEditIndex = index
      const widgetObject = this.departmentWidgets[index]
      const { dateRangeOption, tableColumns } = widgetObject
      const dataRangeTime = []
      let dayPast = '10'
      let dayFuture = '10'
      const singleTableColumns = tableColumns.map(({ key }) => key)
      dateRangeOption.forEach(r => {
        dataRangeTime.push(r.key)
        if (r.key === 'past') {
          dayPast = r.days
        } else if (r.key === 'future') {
          dayFuture = r.days
        }
      })
      this.departmentWidgetObject = {
        module: widgetObject.module,
        statusOptions: widgetObject.statusOptions,
        extraOptions: widgetObject.extraOptions,
        filterBy: widgetObject.filterBy,
        dataFreshness: widgetObject.dataFreshness,
        dataRangeTime,
        dayPast,
        dayFuture,
        tableColumns: singleTableColumns,
      }

      this.isSubModuleEmpty = false
      this.subModuleEventDepartmentForEdit(widgetObject.module)
      this.$root.$emit('bv::show::modal', 'create-department-dashboard-widget-modal', '')
    },

    deleteDepartmentDashboardWidget(index) {
      this.$swal({
        title: 'Delete the widget?',
        html: 'The widget will be deleted and you can create a new widget again',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete the tab',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.departmentWidgets.splice(index, 1)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
.apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  margin-left: 0rem !important;
  padding-left: 3px !important;
}
</style>

<style scoped>
  .customCheckbox {
    border-right-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left-width: 1px !important;
  }

  .btn-group-toggle [class*="btn-outline-"]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: contents;
    vertical-align: middle;
  }
</style>
